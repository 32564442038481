#pinwheel-loader, #pinwheel-loader::before, #pinwheel-loader::after {
    display: block;
    content: "";
    position: absolute;
    /* top: 50%; */
    left: 50%;
    /* transform: translate(-50%, -50%); */
    /* bottom: 0; */
    /* background: #fae281; */
    /* background: conic-gradient(red 0deg 90deg, blue 90deg 270deg, green 270deg 360deg); */
    /* background: var(--g3); */
    /* transform-origin: 50% 50%; */
    transform-origin: 50% 50%;
    background-color: aqua;
    width: 50px;
    height: 50px;
  }
  #pinwheel-loader::before {
    position: absolute;
    top: -2em;
    /* top: -4em; */
    left: -0.6em;
    width: 2em;
    height: 5em;
    transform: rotate(-45deg);
    -moz-border-radius-bottomleft: 2em;
    -webkit-border-bottom-left-radius: 2em;
    border-bottom-left-radius: 2em;
    -moz-border-radius-topright: 2em;
    -webkit-border-top-right-radius: 2em;
    border-top-right-radius: 2em;
    animation: loading3b 2s infinite linear;
    background: var(--g3);
  }
  #pinwheel-loader {
    /* background: #fac1a2; */
    background: #61433d;
    top: 2em;
    height: 6em;
    width: 0.4em;
    -moz-border-radius-bottomright: 2em;
    -webkit-border-bottom-right-radius: 2em;
    border-bottom-right-radius: 2em;
    -moz-border-radius-bottomleft: 2em;
    -webkit-border-bottom-left-radius: 2em;
    border-bottom-left-radius: 2em;
  }
  #pinwheel-loader::after {
    position: absolute;
    top: -2em;
    left: -0.6em;
    width: 2em;
    height: 5em;
    transform: rotate(45deg);
    -moz-border-radius-bottomleft: 2em;
    -webkit-border-bottom-left-radius: 2em;
    border-bottom-left-radius: 2em;
    -moz-border-radius-topright: 2em;
    -webkit-border-top-right-radius: 2em;
    border-top-right-radius: 2em;
    animation: loading3a 2s infinite linear;
    background: var(--g3);
  }
  
  @-moz-keyframes loading3b {
    0% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(680deg);
    }
  }
  @-webkit-keyframes loading3b {
    0% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(680deg);
    }
  }
  @keyframes loading3b {
    0% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(680deg);
    }
  }
  @-moz-keyframes loading3a {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }
  @-webkit-keyframes loading3a {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }
  @keyframes loading3a {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }