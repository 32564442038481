.inputContainer_titleAtBox {
  /* background-color: rgb(226, 76, 76); */
  margin: 10px 10% 12px 10%;
  position: relative;
  top: -10px;
}

.inputContainer_titleAtBox_v2 {
  /* background-color: rgb(226, 76, 76); */
  margin: -10px 5% 0 0;
  position: relative;
}

.inputContainer_nonTitle {
  /* background-color: rgb(226, 76, 76); */
  margin: 10px 0% 10px 0%;
  position: relative;
}

.inputTitle_titleAtBox {
  font-size: 0.9rem;
  color: var(--titleColor);
  width: fit-content;
  position: relative;
  top: 9px;
  left: 8px;
  /* background-color: white; */
  background-color: var(--bgc);
  padding: 0px 5px;
  /* background-color: rgb(216, 105, 105); */
}

.inputText_titleAtBox {
  /* background-color: rgb(221, 83, 83); */
  width: 100%;
  border: 1px solid var(--titleColor);
  /* border: 1px solid rgb(158, 157, 157); */
  background-color: var(--bgc);
  border-radius: 7px;
  padding: 8px 10px 5px 10px;
}

.inputText_titleAtBox:focus {
  outline: none;
}

.inputErrMsg {
  /* margin-top: -2px; */
  padding-left: 10px;
  font-size: 0.75rem;
  position: absolute;
  color: tomato;
  /* display: none; */
}

.inputInstMsg {
  /* margin-top: 10px; */
  /* margin: 10px 0; */
  padding: 10px 0 0 10px;
  /* padding-left: 10px; */
  font-size: 0.75rem;
  color: var(--grey5);
  /* display: none; */
}

.greyOutText {
  color: var(--grey5);
}

.greyOutIcon {
  color: var(--grey3);
}

.smallInputTitle_titleAtBox {
  font-size: 0.7rem;
  color: var(--titleColor);
  /* color: red; */
  width: fit-content;
  position: relative;
  top: 9px;
  left: 8px;
  /* background-color: white; */
  background-color: var(--bgc);
  padding: 0px 5px;
  /* background-color: rgb(216, 105, 105); */
}

.smallInputText_titleAtBox {
  /* background-color: rgb(221, 83, 83); */
  width: 50%;
  border: 1px solid var(--titleColor);
  /* border: 1px solid rgb(158, 157, 157); */
  background-color: var(--bgc);
  border-radius: 7px;
  padding: 8px 10px 5px 10px;
  font-size: 0.9rem;
  height: 1.8rem;
}

.smallInputText_titleAtBox:focus {
  outline: none;
}

.smallInputTitle_titleAtBox_v2 {
  font-size: 0.5rem;
  color: var(--titleColor);
  /* color: red; */
  width: fit-content;
  position: relative;
  top: 9px;
  left: 8px;
  /* background-color: white; */
  background-color: var(--bgc);
  padding: 0px 5px;
  /* background-color: rgb(216, 105, 105); */
}

.smallInputText_titleAtBox_v2 {
  /* background-color: rgb(221, 83, 83); */
  width: 100%;
  border: 1px solid var(--titleColor);
  /* border: 1px solid rgb(158, 157, 157); */
  background-color: var(--bgc);
  border-radius: 7px;
  padding: 8px 10px 5px 10px;
  font-size: 0.6rem;
  height: 1.3rem;
  /* margin-top: -20px; */
}

.smallInputText_titleAtBox_v2:focus {
  outline: none;
}

.inputText_noTitle {
  /* background-color: rgb(221, 83, 83); */
  width: 10%;
  border: 1px solid var(--titleColor);
  /* border: 1px solid rgb(158, 157, 157); */
  background-color: var(--bgc);
  border-radius: 7px;
  margin: 0 30px;
  height: 1.2rem;
  font-size: 0.8rem;
  padding: 3px 5px 3px 5px;
}

.showHideIcon {
  position: absolute;
  top: 25px;
  right: 25px;
}