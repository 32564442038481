.ssPHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 30px 0px;
  font-size: 1.4rem;
  /* background-color: #fff; */
}

.ssPHeaderDevName {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(121, 37, 199); */
  height: 60px;
}

.bellIcon {
  /* width: 30px;
  height: 30px; */
  /* background-color: rgb(163, 219, 180); */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px; /* shift down bell position. make align with text*/
}

.timeSelect {
  border: 1px solid var(--grey6);
  border-radius: 4px;
  width: 40px;
  text-align: center;
  margin: 0px 5px;
  /* background-color: rgb(247, 208, 208); */
  font-size: 0.8rem;
}

.singleSensorGraph {
  width: 100%;
  height: 100px;
  background-color: rgb(155, 169, 214);
  margin: 0px 20px;
}
