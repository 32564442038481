.devOvvBdTitleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  /* padding: 0px 5%; */
  /* background-color: rgb(231, 73, 73); */
}

.tpDevOvv_Title {
  /* position: absolute;
  top: 0;
  left: 15px; */
  line-height: 1.5rem;
  height: 3.5rem;
  overflow: hidden;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%; */

  font-weight: 500;
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;
}

/* .errBgc {
  background-color: var(--ErrColorDark_1);
} */

.devOvvBdTitleIcon {
  font-size: 1.3rem;
  /* background-color: rgb(103, 219, 25); */
}

.devOvvBdTitleName {
  display: flex;
  font-size: 1.2rem;
  /* background-color: rgb(216, 38, 38); */
  justify-content: center;
  align-items: center;
  width: 80%;
}

/* .devOvvBdTitleName:hover {
  cursor: pointer;
} */

.devCount {
  /* margin: 15px 0px; */
  font-size: 0.8rem;
  /* background-color: rgb(47, 194, 186); */
}
/* .devCount:hover {
  cursor: pointer;
} */

.devOvvBdName {
  font-size: 1.5rem;
  font-weight: 500;
  max-width: 60%;
  margin: 0px 10px;
  /* background-color: rgb(40, 183, 219); */
}

.bdOvvAreaName {
  margin-top: 30px;
  font-size: 1.2rem;
  /* background-color: rgb(224, 36, 36); */
}

.bdOvvAreaDevCnt {
  font-size: 0.8rem;
}

.bdOvvDevContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 5%;
  /* background-color: rgb(214, 16, 16); */
}

.bdOvvDevFrame {
  margin: 10px 0px;
  width: 350px;
  /* height: 70px; */
  /* border: 1px solid var(--grey2); */
  border-radius: 10px;
  /* background-color: var(--bgc); */
  background-color: rgb(240, 240, 240);

  box-shadow: -8px -8px 8px rgb(255, 255, 255),
    -8px -8px 8px rgba(255, 255, 255, 0.5),
    inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);
}

.devBdFrame {
  margin: 10px 0px;
  width: 320px;
  min-height: 420px;
  /* height: 70px; */
  /* border: 1px solid var(--grey2); */
  border-radius: 10px;
  /* background-color: var(--bgc); */
  background-color: rgb(240, 240, 240);

  box-shadow: -8px -8px 8px rgb(255, 255, 255),
    -8px -8px 8px rgba(255, 255, 255, 0.5),
    inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);
}

@media (orientation: landscape) {
  .bdOvvDevFrame {
    width: 350px;
  }
}
@media (orientation: landscape) and (min-width: 800px) {
  .bdOvvDevFrame {
    width: 350px;
    /* background-color: rgb(214, 30, 30); */
  }
}

.tpDevOvv_IconGroup {
  margin: 10px;
  height: 100px;
  /* background-color: rgb(230, 31, 31); */
}

.tpDevOvv_BattSigGroup {
  /* background-color: rgb(73, 233, 100); */
}

.tpDevOvvIcon {
  /* color: aquamarine; */
  font-size: 2.8rem;
  margin: 0px 10px;
  background-color: rgb(208, 188, 255);
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
}

.tpDevOvvIcon:hover {
  cursor: pointer;
}

.tpDevOvvBattSig {
  /* background-color: rgb(17, 166, 192); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
}

.tpDevOvvBattSigIcon {
  /* background-color: rgb(42, 207, 21); */
}

.tpDevOvvBattSigText {
  font-size: 0.7rem;
  /* margin-left: 2px; */
  /* background-color: rgb(127, 235, 26); */
  text-align: center;
  width: 30px;
}

.tpDevOvv_dataGroupContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.tpDevOvv_dataContainer {
  width: 50%;
  /* background-color: rgb(230, 34, 34); */
  margin: 8px 0px;
}

.tpDevOvv_dataContainer:hover {
  cursor: pointer;
}

.tpDevOvv_dataTitle {
  /* background-color: rgb(223, 29, 29); */
  font-size: 0.7rem;
  padding-left: 3px;
  text-align: center;
  /* border-bottom: 2px solid olive; */
}

.tpDevOvv_dataUnit {
  /* position: absolute; */
  text-align: center;
  font-size: 0.6rem;
  margin-top: -6px;
  height: 0.9rem;
  /* background-color: rgb(216, 39, 39); */
}

.tpDevOvv_LineColor {
  position: absolute;
  border-left: 2px solid var(--g2);
  border-radius: 3px;
  height: 30px;
  width: 4px;
  margin: 3% 0px 2% 3px;
}

.tpDevOvv_dataValue {
  font-size: 1.1rem;
  font-weight: 500;
  padding-left: 6px;
  text-align: center;
}

.tpDevOvv_lastUpdate {
  /* background-color: rgb(68, 170, 211); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-left: 5px;
  font-size: 0.7rem;
  /* visibility: hidden; */
  /* text-align: center; */
  position: absolute;
  color: var(--ErrColor);
  top: 0px;
}

.expandButton {
  display: flex;
  justify-content: center;
  /* background-color: rgb(204, 20, 20); */
  margin-top: 5px;
  /* margin-top: auto; */
  flex: 1;
}

.expandButton:hover {
  cursor: pointer;
}

.tpDevOvv_ExpContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(40, 188, 207); */
}

.lnGradient_1 {
  background-image: var(--g1);
}
.lnGradient_2 {
  background-image: var(--g2);
}
.lnGradient_3 {
  background-image: var(--g3);
}
.lnGradient_4 {
  background-image: var(--g4);
}

.graphDevOvv_Title {
  /* line-height: 1.5rem;
  height: 3.5rem;
  overflow: hidden; */
  /* white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%; */

  font-weight: 500;
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;
}

.graphOvvDevFrame {
  /* position: relative; */
  margin: 20px 3%;
  padding: 5px;
  width: 94%;
  /* height: 400px; */
  /* border: 1px solid var(--grey2); */
  border-radius: 10px;
  /* background-color: var(--bgc); */
  background-color: rgb(240, 240, 240);

  box-shadow: -8px -8px 8px rgb(255, 255, 255),
    -8px -8px 8px rgba(255, 255, 255, 0.5),
    inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);
}

#watchList-graph {
  width: 70%;
  /* position: absolute; */
}

.cb-Title {
  margin: 0 9px;
}

.sensorGraph_LineColor {
  /* position: absolute; */
  /* border-left: 2px solid var(--g2); */
  height: 5px;
  width: 30px;
  border-radius: 3px;
  /* background-color: brown; */
  margin: 8px 5px 8px 5px;
}

.sensorGraph_Indicator {
  font-size: 0.8rem;
}

.sensorGraph_Indicator:hover {
  cursor: pointer;
}