.lds-ring {
    display: inline-block;
    position: fixed;
    /* position: relative; */
    width: 80px;
    height: 80px;
    top: 45%;
    right: 45%;
    z-index: 100;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--grey2);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--grey2) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .lds-default {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .lds-default div {
    position: absolute;
    width: 1.5px;
    height: 1.5px;
    background: var(--grey2);
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 9.25px;
    left: 16.5px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 5.5px;
    left: 15.5px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 2.75px;
    left: 13px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 1.75px;
    left: 9.25px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 2.75px;
    left: 5.5px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 5.5px;
    left: 2.75px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 9.25px;
    left: 1.75px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 13px;
    left: 2.75px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 15.5px;
    left: 5.5px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 16.5px;
    left: 9.25px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 15.5px;
    left: 13px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 13px;
    left: 15.5px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  