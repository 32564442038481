.settingItemContainer {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.settingItem {
  margin: 5px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.settingItem:hover {
  cursor: pointer;
}

.settingItemIcon {
  padding: 0px 15px;
  display: flex;
  align-items: center;
}

.settingItemIcon:hover {
  cursor: pointer;
}

.settingItemText {
  /* background-color: rgb(53, 206, 147); */
}

.scanQrCodeContainer {
  /* background-color: rgb(197, 50, 50); */
  margin: 20px 10% 0px 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgb(158, 157, 157);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.scanQrCodeIcon {
  width: 40px;
  height: 40px;

  /* background-color: rgb(60, 180, 130); */
  margin-bottom: 5px;
}
