.grid-container {
    display: grid;
    /* grid-auto-flow: row; */
}

.gridConfig3x3 {
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(3, 200px); 
    grid-template-columns: repeat(3, 400px); */
    gap: 10px;
}

.gridSize1x1 {
    grid-row: span 1;
    grid-column: span 1;
}

.gridSize1x2 {
    grid-row: span 1;
    grid-column: span 2;
}

.gridSize1x3 {
    grid-row: span 1;
    grid-column: span 3;
}