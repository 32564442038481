.flexOnly {
  display: flex;
}

.flexWrap{
  display: flex;
  flex-wrap: wrap;
}

.flex_1 {
  flex: 1;
  /* background-color: rgb(223, 19, 19); */
}
.flex_2 {
  flex: 2;
  /* background-color: rgb(223, 19, 19); */
}
.flex_4 {
  flex: 4;
}
.flex_8 {
  flex: 8;
}
.flex_9 {
  flex: 9;
}
.sortHor {
  display: flex;
  align-items: center;
  /* background-color: rgb(226, 42, 42); */
}
.rotate90deg{
  transform: rotateZ(90deg);
  /* background-color: #b41818; */
}

.sortVer {
  display: flex;
  flex-direction: column;
}


.spreadBetween {
  justify-content: space-between;
}

.spreadAround {
  justify-content: space-around;
}

.spreadEvenly {
  justify-content: space-evenly;
}

.sortMiddle {
  display: flex;
  justify-content: center;
}

.flexHorCenter {
  display: flex;
  justify-content: center;
}

.flexVerCenter {
  display: flex;
  align-items: center;
}

.flexAndBothCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stayRight {
  margin-left: auto;
}

.stayBottom{
  position: absolute;
  bottom: 0;
}

.testBgc{
  background-color: rgb(179, 32, 32);
}
.greenBgc{
  background-color: var(--btnGreen);
}
.square_25{
  height: 25px;
  width: 25px;
}

.flexColumn {
  flex: 25%;
  max-width: 25%;
  max-height: 25%;
}

/* .flexColumn div {
  vertical-align: middle;
} */

/* Responsive layout - makes a two column-layout instead of four columns */
@media (max-width: 800px) {
  .flexColumn {
    flex: 50%;
    max-width: 50%;
    max-height: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
  .flexColumn {
    flex: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}