.tpReading {
    font-size: 1.8rem;
}

.tpMinMax {
    font-size: 0.8rem;
    color: var(--grey4);
    margin-top: -8px;
}

.tpMinMax_reading {
    color: var(--grey8);
    margin-left: 5px;
    font-size: 0.8rem;
}

.tpUnit {
    /* margin-left: 50px; */
    font-size: 1.5rem;
}

.tpParamsContainer {
    margin: 8px 0;
}

.tpIndicator {
    height: 20px;
    width: 20px;
    border-radius: 3px;
    margin: 0 15px 0 25px;
}

.tpIndicator:hover {
    cursor: pointer;
}

.tpReadingContainer {
    width: 100%;
    margin-right: 20px;
}

.tpReadingContainer:hover {
    cursor: pointer;
}

.tpSettingContainer {
    margin: 0px 7px;
}

.tpGraphContainer {
    margin: 5px;
}

.tpGraph_smallIcon {
    font-size: 0.5rem;
    margin-top: 10px;
    color: var(--btnGreen);
}

.tpReadingTitle{
    font-size: 0.7rem;
    margin-bottom: -10px;
}