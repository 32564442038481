.i_twBox_input {
  width: 100%;
  /* background-color: rgb(233, 62, 62); */
  outline: none;
  border: none;
  /* border-bottom: 1px solid var(--grey4); */
  background-color: var(--cardWhite);
  color: var(--blueText);
  font-size: 1.2rem;
}

.i_twBox_input::placeholder {
  color: var(--greyoutText);
}

.i_twBox_contentInput {
  width: 100%;
  outline: none;
  border: none;
  /* border-bottom: 1px solid var(--grey4); */
  background-color: var(--cardWhite);
  color: var(--blueText);
}

.i_time_contentInput {
  outline: none;
  border: none;
  /* border-bottom: 1px solid var(--grey4); */
  background-color: var(--cardWhite);
  color: var(--blueText);
}

.i_twBox_contentInput::placeholder {
  color: var(--greyoutText);
}

.i_twBox_spaceBtm {
  margin-bottom: 3px;
}

.i_wordBreak {
  min-height: 120px;
  height: 120px;
  max-height: 200px;
}

.i_twBox_title {
  margin: 10px 0px 0px 0px;
}

.rCfg_Width100px {
  width: 120px;
}

.rCfg_Width70px {
  width: 70px;
}
