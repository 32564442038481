.column {
  float: left;
  width: 33%;
  padding-left: 1%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.card {
  width: 99%;
  margin-left: 1%;
  background-color: transparent;
  border-color: #37474f;
  margin-top: 15px;
  margin-bottom: 5px;
  border-radius: 10px;
  /* box-shadow: 0 0 10px #383d46; */

  box-shadow: 3px 3px 3px #263238;
}

.outer {
  width: 98%;
  margin-right: 1%;
}

.outer .card-header:hover {
  cursor: pointer;
}

.outer .card-body {
  padding-left: 0.3%;
  padding-right: 0.3%;
}

.card-body {
  /* margin-top: -5px; */
  padding: 0px;
}

.card-header {
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  overflow-wrap: break-word;
  background-image: linear-gradient(to right, #263238, #455a64);
}

.element .card-header {
  background-image: linear-gradient(to right, #37474f, #546e7a);
}

@media only screen and (max-width: 800px) {
  .column {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }
  .card {
    width: 95%;
    margin-left: 2%;
    background-color: transparent;
    border-color: #37474f;
    margin-top: 15px;
    margin-bottom: 5px;
    border-radius: 10px;
    /* box-shadow: 0 0 10px #383d46; */

    box-shadow: 3px 3px 3px #263238;
  }
}
