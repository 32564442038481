.divHidden {
  /* still occupy space */
  visibility: hidden;
}

.div0Height{
  height: 0px;
  width: 0px;
}

.divCollapse {
  display: none;
}

.divSeperateLine {
  border-bottom: 1px solid var(--grey1);
  width: 100%;
}

.divSpaceUp_20px {
  margin: 20px 0px 0px;
}

.divShortSeperateLine {
  border-bottom: 1px solid var(--grey1);
  width: 50%;
  margin-left: 25%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.divStickOnTop {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* background-color: var(--headerColor); */
}

.divStickOnBottom {
  /* display: block; */
  /* position: -webkit-sticky; */
  /* position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky; */
  /* position: sticky; */
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  /* background-color: var(--headerColor); */
}

/* hide number up down button
Chrome, Safari, Edge, Opera */
.hideNumberUpDown::-webkit-outer-spin-button,
.hideNumberUpDown::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
hideNumberUpDown[type="number"] {
  -moz-appearance: textfield;
}

.commonCheckBox {
  border: 3px solid var(--grey3);
  border-radius: 5px;
}

.greenCheck {
  color: var(--HealthColor);
}

.checkBoxSize_1 {
  width: 25px;
  height: 25px;
  /* background-color: rgb(236, 35, 35); */
}

.marginLR_10px{
  margin: 0px 10px;
}

.marginR_10px{
  margin: 0px 10px 0px 0px;
}
.marginR_5px{
  margin-right: 5px;
}

.paddingR_10px{
  padding:0px 10px 0px 0px;
}
/* .marginR_10px{
  margin: 0px 10px 0px 0px;
} */