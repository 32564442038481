

.spring_dialogBoxContainer{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    /* background-color: rgb(171, 171, 171); */
    /* opacity: 0.8; */

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102;
}

.spring_manuContainer{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgb(176, 176, 176);
    opacity: 0.7;
    z-index: 100;
    /* align-items: center; */
}

.spring_diaFixedHeight{
    height: 400px;
}

.spring_diaCloseBtnFixedHeight{
    /* margin-left: auto; */
    position: absolute;
    height: 43px;
    width: 43px;
    border-radius: 30px;
    background-color: brown;
    /* margin: -18px -293px 0px 0px; */
    top: -18px ;
    left: 280px;
    color: var(--cardWhite);
    display: flex;
    justify-content: center;
    align-items: center;

    /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5),
    5px 5px 5px rgb(0, 0, 0),
    inset 2px 2px 5px rgba(155, 155, 155, 0.08), 
    5px 5px 5px rgba(225, 225, 225, 0.1); */
}

.spring_marginLeft_n30{
    margin-left: -30px;
}

.spring_marginLeft5{
    margin-left: 5px;
}
.spring_marginLeft10{
    margin-left: 10px;
}
.spring_marginLeft20{
    margin-left: 20px;
}
.spring_marginR_10per{
    margin-right: 10%;
}
.spring_marginRight10{
    margin-right: 10px;
}
.spring_marginRight5{
    margin-right: 5px;
}
.spring_marginRight3{
    margin-right: 3px;
}
.spring_marginRight15{
    margin-right: 15px;
}
.spring_MarginTop_n5{
    margin-top: -4px;
}
.spring_MarginTop_5{
    margin-top: 5px;
}
.spring_MarginTop_10{
    margin-top: 10px;
}
.spring_MarginTop_20{
    margin-top: 20px;
}
.spring_MarginBtm_5{
    margin-bottom: 5px;
}
.spring_MarginBtm_10{
    margin-bottom: 10px;
}

.spring_Margin_10{
    margin: 10px;
}
.spring_Margin_20{
    margin: 20px;
}
.spring_Margin_30{
    margin: 30px;
}

.spring_Margin_40{
    margin: 40px;
}

.spring_MarginLeftRight_10Per {
    margin: 10px 10%;
}

.spring_MarginLeftRight_20Per {
    margin: 10px 20%;
}

.spring_MarginTopBtm_4px {
    margin: 4px 0px;
}

.spring_paddingLeft5{
    padding-left: 5px;
}

.spring_paddingLeft10{
    padding-left: 10px;
}

.spring_paddingTop10{
    padding-top: 5px;
}

.spring_padding5 {
    padding: 5px;
}

.spring_width30px{
    width: 30px;
}

.spring_width80per{
    width: 80%;
}

.spring_width70per{
    width: 70%;
}

.spring_failedBgc{
    background-image: linear-gradient(135deg, rgb(255, 106, 106), rgb(120, 23, 4));
}

.spring_greenGrdBgc{
    background-image: linear-gradient(135deg, rgb(106, 255, 114), rgb(2, 120, 61));
}

.spring_blueGrdBgc{
    background-image: linear-gradient(135deg, rgb(106, 181, 255), rgb(10, 47, 140));
}

.spring_lightGreyGrdBgc{
    background-image: linear-gradient(135deg, rgb(214, 214, 214), rgb(131, 131, 131));
}

.spring_cardFrame{
    /* background-color: rgb(218, 21, 21); */
    margin: 5px;
    padding: 5px;
    /* border: 1px solid grey; */
    border-radius: 5px;
}


.spring_tpFrame{
    padding: 10px 0px 5px 0px;
    background-color: var(--bgc);
    width: 300px;
    margin: 20px;
    /* border: 1px solid grey; */
    border-radius: 5px;
}

.spring_rightInput{
    margin-left: auto;
    width: 60px;
    /* background-color: #fff; */
    text-align: center;
    /* align-items: center; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.spring_ContentArea{
    background-color: var(--cardWhite);
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    margin-top: 5px;
}


.spring_ContentHeader{
    margin: 2px 0px;
    padding-left: 5px;
}

.spring_tpTitle{
    /* background-color: #fff; */
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 10px;
}

.spring_seperationLine{
    background-color: var(--grey2);
    height: 1px;
    margin: 0px 5px;
    /* width: 100%; */
}

.spring_roundButton{
    /* width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: rgb(184, 184, 184);
    text-align: center; */
    
    border-radius: 30px;
    width: 60px;
    height: 60px;
    /* background-color: rgb(174, 7, 34); */
    
    color: white;
    font-weight: 600;
}

.spring_bgi_GrGreen{
    background-image:var(--spring_button_R_G);    
}

.spring_bgi_GrRed{
    background-image: linear-gradient(135deg, rgb(246, 31, 31), rgb(159, 5, 5));
}

.spring_buttonSpace{
    padding: 20px 0px;
}

.spring_shadowBox{
    
  box-shadow: -8px -8px 12px rgb(255, 255, 255),
  -8px -8px 12px rgba(255, 255, 255, 0.5),
  inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);
}
.spring_shadowBox_Small{    
    box-shadow: -4px -4px 6px rgb(255, 255, 255),
    -4px -4px 6px rgba(255, 255, 255, 0.5),
    inset 4px 4px 2px rgba(255, 255, 255, 0.1), 4px 4px 6px rgba(0, 0, 0, 0.15);
  }


.spring_shadowBox_BlackOnly{
    
    box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.15);
  }

.spring_HeaderText{
    font-size: 1.1rem;
}

.spring_BtnBtm{
    display: flex;
    flex: 1;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    
    padding: 10px 0px;
    margin-top: 5px;
    margin-bottom: -10px;

    /* font-size: 1.1rem; */
    font-weight: 500;
    color: white;
    /* color: black; */
}

.spring_BtmBorRad_L{
    border-radius: 0px 0px 0px 5px;
}

.spring_BtmBorRad_R{
    border-radius: 0px 0px 5px 0px;
}

.spring_BtmBtn_Grey{
    background-image: linear-gradient(135deg, rgb(192, 192, 192), rgb(151, 151, 151));
}

.spring_BtmBtn_Green{
    background-image: linear-gradient(135deg, rgb(36, 253, 159), rgb(8, 203, 118));
}

.spring_rotate180{
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
    /* background-color: rgb(165, 20, 20); */
}

.spring_rotate0{
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    /* background-color: rgb(165, 20, 20); */
}

.spring_showItemAni{
    /* display: flex; */
    visibility: visible;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.spring_hideItemAni{
    /* display: none; */
    visibility: hidden;
    height: 0px;
    transition: transform 0.3s ease-in-out;
}

.spring_dialogBlurBg{
    position:absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgb(227, 227, 227);
    opacity: 0.8;
    z-index: 100;
}



.spring_subDialogBoxContainer{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    /* background-color: rgb(171, 171, 171); */
    /* opacity: 0.8; */

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 104;
}

.spring_dialogBox{
    z-index: 101;
    align-self: center;
    width: var(--dialogBoxWidth);
    background-color: var(--cardWhite);
    opacity: 1;
    border-radius: 10px;
/* background-color: #b43232; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spring_dialogBoxStayLeft{
    z-index: 101;
    align-self: center;
    width: var(--dialogBoxWidth);
    background-color: var(--cardWhite);
    opacity: 1;
    border-radius: 10px;

    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.spring_dialogList{
    z-index: 101;
    /* align-self: center; */
    width: var(--dialogBoxWidth);
    background-color: var(--cardWhite);
    opacity: 1;
    border-radius: 10px;

    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.spring_centerText{
    text-align: center;
}
/* .spring_diaMarigin{
    padding: 30px 0px;
} */

.spring_diaIconFrame{
    margin: 80px 0px 30px 0px;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: rgb(228, 62, 62);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--cardWhite);
    font-size: 40px;
}

.spring_diaIcon_Bigger{
    font-size: 43px;
}

.spring_diaStatusText{
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 40px;
}

.spring_vibration{
    animation: spvibrate 5s ease-out infinite;
}

@keyframes spvibrate{
    0%{
        transform: rotate(-5deg);
    }
    50%{
        transform: rotate(10deg);
    }
    100%{
        transform: rotate(-5deg);
    }
}

.spring_FullWidth{
    width: 100%;
    /* background-color: rgb(117, 74, 212); */
}

.spring_DiaBtmButton{
    display: flex;
    flex: 1;
    /* background-color: rgb(206, 45, 45); */
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
}


.spring_DiaButton{
    width: 90px;
    background-color: rgb(186, 27, 27);
    border-radius: 50px;
    text-align: center;
    color: var(--cardWhite);
    padding: 3px;
    font-weight: 500;
    margin: 20px 0px 30px 0px;
}

.spring_diaCloseBtn{
    /* margin-left: auto; */
    position: absolute;
    height: 43px;
    width: 43px;
    border-radius: 30px;
    background-color: brown;
    margin: -18px -293px 0px 0px;
    color: var(--cardWhite);
    display: flex;
    justify-content: center;
    align-items: center;

    /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5),
    5px 5px 5px rgb(0, 0, 0),
    inset 2px 2px 5px rgba(155, 155, 155, 0.08), 
    5px 5px 5px rgba(225, 225, 225, 0.1); */
}

.spring_gwSelBar{
    border-radius: 80px ;
    background-color: rgb(186, 186, 186);
    width: 120px;
    text-align: center;
    margin: 3px;

    color: var(--cardWhite);
}

.spring_gwInputBar{
    width: 80px;
    outline: none;
    border: 1px solid var(--grey2);
}
.spring_gwInputBar:focus{
    outline: none;
}

.spring_FramelessInput{
    outline: none;
    border: 1px solid var(--grey2);
}
.spring_FramelessInput:focus{
    outline: none;
}
.spring_RoundSetButton{
    border-radius: 30px;
    width: 28px;
    height: 28px;
    margin-left: 5px;
    text-align: center;
    color: var(--cardWhite);
}

.spring_diaSmallTitle{
    margin-top: 10px;
    font-size: 0.7rem;
    /* background-color: rgb(171, 21, 21); */
    width: 120px;
    text-align: left;
}

.spring_diaTitle{
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 40px;
}

.spring_diaTitle_SmallMarginTop{
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 20px;
}

.spring_diaSpaceBelow{
    margin-bottom: 40px;
}

.spring_syncButton{
    margin-right: 10px;
    color: var(--btnGreen);
}


.spring_slaveButton{
    width: 40px;
    /* height: 50px; */
    background-color: rgb(203, 49, 49);
    border-radius: 30px;
    text-align: center;
    margin-left: 10px;
    color: var(--cardWhite);

    display: flex;
    justify-content: center;
    align-items: center;
}

.spring_slaveButtonGroup{
    margin-left: auto;
    /* margin: 5px 0px 5px auto; */
}

.spring_slaveCtrlGroup{
    margin: 10px 0px;
}

.spring_smallerText_08{
    font-size: 0.8rem;
}

.spring_diaSearchBar{
    border-radius: 5px;
    border: 1px solid var(--grey2);
}

.spring_SelectedTab{
    background-color: rgb(184, 255, 153);
    border-radius: 10px;
    padding: 0px 10px;
}


.spring_OvvTitleName {
    display: flex;
    font-size: 1.2rem;
    /* background-color: rgb(216, 38, 38); */
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .spring_smallText{
    font-size: 0.9rem;
  }

  .spring_miniText{
    font-size: 0.7rem;
  }

  
  .spring_floorContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 5%;
    /* background-color: rgb(214, 16, 16); */
  }

  .spring_floorFrame {
    margin: 10px 0px;
    padding: 7px 15px;
    width: 100%;
    border-radius: 10px;
    background-color: rgb(240, 240, 240);
  
    box-shadow: -8px -8px 8px rgb(255, 255, 255),
      -8px -8px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);
  }

  .spring_floorBigText{
    font-size: 1.1rem;
    font-weight: 500;
  }
  .spring_floorSmallText{
    font-size: 0.8rem;
    /* font-weight: 500; */
  }

    
  .spring_BdContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 15px 20px;
    /* background-color: rgb(214, 16, 16); */
  }
  .spring_BdFrame {
    margin: 12px 0px;
    padding: 13px 15px;
    width: 100%;
    border-radius: 10px;
    background-color: rgb(240, 240, 240);
  
    box-shadow: -8px -8px 8px rgb(255, 255, 255),
      -8px -8px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);
  }


  .spring_statusGroup{
    margin-left: auto;
  }

  .spring_addDevStepTitle {
    margin: 30px 0px 0px 10%;
    font-size: 0.8rem;
  }
  .spring_addDevStep {
    margin: 0px 10% ;
    /* margin: 0px 0px 0px 10%; */
    font-size: 1.1rem;
    font-weight: 500;
  }
  
.spring_verifyButton{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 70px;
    background-color: var(--btnGreen);
    border-radius: 50px;
    text-align: center;
    color: azure;
    padding: 5px;
    font-weight: 500;
    font-size: 0.9rem;
  }


.spring_blueColor{
  background-color: var(--btnBlue);
}

.spring_greyColor{
    background-color: var(--grey5);
  }

.spring_checkBox{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--grey4);
    border-radius: 3px;
    height: 15px;
    width: 15px;
    margin-right: 5px;
    color: var(--btnGreen);
    padding: 1px;
}

.spring_biggerCheckBox{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--grey4);
    border-radius: 3px;
    height: 15px;
    width: 15px;
    margin-right: 5px;
    color: var(--btnGreen);
    padding: 1px;
}

.spring_skipClick{
    font-weight: 400;
    color: var(--blueText);
}

.spring_fullButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    /* width: 100px; */
    /* background-color: var(--btnGreen); */
    border-radius: 50px;
    text-align: center;
    color: azure;
    padding: 5px;
    font-weight: 500;
    /* font-size: 1.1rem; */
  }

  .spring_roundButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 100px;
    border-radius: 50px;
    text-align: center;
    color: azure;
    padding: 5px;
    font-weight: 500;
    /* font-size: 1.1rem; */
  }
  

  .spring_manuFrame{
    display: flex;
    justify-content: flex-end;
  }



.spring_manuBox{
    position: absolute;
    z-index: 101;
    width: 300px;
    width: var(--dialogBoxWidth);
    /* height: 200px; */
    background-color: var(--bgc);
    opacity: 1;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    /* background-color: rgb(244, 244, 244); */
    /* background-color: var(--cardWhite); */
    margin-top: 20px;
    margin-right: 18px;
}

.spring_SortFrame {
    margin: 5px 0px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 30px;
    background-color: rgb(240, 240, 240);
    border: 1px solid var(--grey4);
  
    /* box-shadow: -8px -8px 8px rgb(255, 255, 255),
      -8px -8px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15); */
}

.spring_SortIcon {
    width: 30px;
    margin: 0px 5px;
}

.spring_SortIcon_dia {
    width: 20px;
    margin: 0px 5px;
}

.spring_width_80_per{
    width: inherit;
    margin-right: 20px;
}

.spring_renameBdName{
    margin: 40px 0px 30px 0px;
    /* font-size: 1.1rem; */
    font-weight: 500;
}
.spring_diaSmallDesc{
    margin: 0px 0px 30px 0px;
    font-size: 0.9rem;
    /* font-weight: 500; */
}

.spring_inputContainer {
    /* background-color: rgb(226, 76, 76); */
    margin: 10px 10% 12px 10%;
}


.spring_inputBox {
    /* background-color: rgb(226, 76, 76); */
    margin: 0px 10%;
    position: relative;
}

.spring_ddTitleContainer {
    margin: 10px 10% 13px 10%;
}


.spring_nextInputTitle{
    margin: 30px 10% 10px 10%;
}
.spring_DiaSelDev_Title{
    margin: 20px ;
    /* background-color: #cf2121; */
}

.spring_listContainer{
    /* border: 1px solid var(--grey2); */
    border-radius: 10px;
    padding: 3px 10px;
    background-color: var(--cardWhite);
}

.spring_containerAddDev{
    
    text-align: center;
}

.spring_addDevTopBorder{
    border-top: 1px solid var(--grey4);
    margin: 5px -11px -5px -11px;
    border-radius: 0px 0px 10px 10px;
    padding: 3px 0px;
}

.spring_addDevAllBorder{
    border: 1px solid var(--grey4);
    margin: -5px -11px -5px -11px;
    border-radius: 10px 10px 10px 10px;
    padding: 3px 0px;
}

.spring_listContainerItem{
    margin: 2px 0px;
    /* background-color: #e72a2a; */
    width: 200px;
}

.spring_2BtnBtmMargin{
    margin: 40px 20px 20px 20px;
}

.spring_whiteCardArea {
    /* background-color: rgb(226, 76, 76); */
    margin: 0px 10%;
    position: relative;
    background-color: var(--cardWhite);
    border-radius: 10px;
    padding: 3px 10px;
}

.spring_whiteCardItem{
    margin: 5px 10px;
}

.spring_WCItemTitle{
    width: 80px;
    /* background-color: rgb(226, 49, 49); */
}

.spring_WCItemValue{
    margin-left:10px;
    /* background-color: rgb(18, 83, 106); */
    
}


.spring_diaSearchInput {
    background-color: var(--cardWhite);
    margin-left: 10px;
    flex: content;
    outline: none;
    border: none;
  }
  
  .spring_diaSearchInput:focus {
    outline: none;
    /* border: 1px solid black; */
    /* background-color: rgb(214, 28, 28); */
  }

  .spring_diaItemFrame{
    border: 1px solid var(--grey4);
    border-radius: 6px;
    margin: 0px 10px 10px 10px;
    height: 200px;
    width: 240px;
    padding: 5px 10px;
    overflow: scroll;
    /* overflow: auto; */
  }

  .spring_diaItemFrame_SubDia{
    border: 1px solid var(--grey4);
    border-radius: 6px;
    height: 200px;
    width: 240px;
    overflow: scroll;
  }

  .spring_TableItemSpace{
    margin: 2px 0px;
  }
  
  .spring_TableItem{
    padding: 2px 10px;
  }

  .spring_diaFilterFrame{
    border: 1px solid var(--grey4);
    border-radius: 6px;
    margin: 10px;
    width: 240px;
    padding: 2px 10px;
    /* overflow: scroll; */
  }

.spring_itemSelected{
    background-color: var(--grey2);
    width: 100%;
}

.spring_itemTitle{
    width: 75px;
}

.spring_choosAreaList{
    border: 1px solid var(--grey2);
    border-radius: 10px;
    margin: 10px;
    width: 100%;
}

.spring_index2{
    z-index: 2;
}
.spring_index1{
    z-index: 1;
}
.spring_index0{
    z-index: 0;
}
.spring_indexN1{
    z-index: -1;
}
.spring_index100{
    z-index: 100;
}
.spring_index101{
    z-index: 101;
}
.spring_index110{
    z-index: 110;
}

.spring_disListFrame{
    overflow: scroll;
}

.spring_SortFrameList {
    margin: 10px 15px;
    padding: 10px 20px;
    /* width: 100%; */
    border-radius: 30px;
    background-color: rgb(240, 240, 240);
    border: 1px solid var(--grey4);
  
    /* box-shadow: -8px -8px 8px rgb(255, 255, 255),
      -8px -8px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15); */
}

.spring_devManuFrame{
    top: 15px;
    left: -185px;
    width: 200px;
    max-height: 200px;
    border-radius: 10px;

    background-color: var(--cardWhite);
    padding-bottom: 5px;
}
.spring_devManuItem{
    margin: 5px 15px;
}

.spring_devManuContainer{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgb(176, 176, 176);
    opacity: 0.3;

    /* align-items: center; */
}

.spring_diaSmallTitle{
    margin: 0px 0px 0px 60px;
}

.spring_diaMainTitle{
    margin:20px 0px 5px 60px;
}

.spring_itemFrame{
    /* border: 1px solid var(--grey2); */
    padding-left: 10px;
}

.spring_FloorTitle{
    margin: 5px 20px -5px 15px;
    
}

.spring_cfgCard{
  border-radius: 5px;
  margin: 10px;
  padding: 10px 5px 5px 10px;
}

.spring_cfgCard_nonPadding{
  border-radius: 5px;
  margin: 10px;
  /* padding: 10px 5px 5px 10px; */
}

.spring_padding_5px{
    padding: 5px 15px;
}

.spring_margin_2_0{
    margin: 2px 0px;
}

.spring_FeatureManuTab{
    position: fixed;
    left: 50%;
    margin-left: -40px; 
    width: 80px;
    height: 36px;
    color: #fff;
    background-image: var(--gLogo);
    /* background-color: var(--blueText); */
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 0px 20px 3px #c6c5c5;

    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 2;
    
    bottom: 0px;
    /* spring_shadowBox */
}

.spring_FeatureManuContainer{
    background-color: var(--cardWhite);
    width: 100vw;
    height:var(--featureManuHeight);
    box-shadow: 0px 0px 20px 3px #c6c5c5;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 2px 0px 5px 0px;
}

.spring_feaTabUp{
    position: fixed;
    bottom: var(--featureManuHeight);
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}
.spring_feaTabDn{
    position: fixed;
    bottom: 0px;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}
.spring_feaContentUp{
    /* bottom: 0px; */
    position: fixed;
    bottom:0vh;
    height: var(--featureManuHeight);
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}
.spring_feaContentDn{
    position: fixed;
    /* bottom: 0px; */
    bottom:0vh;
    height: 0px;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.spring_valueBox{
    border-radius: 10px;
    /* background-color: #f7f7f7; */
    width: 55px;
    height: 55px;    
    border: 1px solid rgb(208, 208, 208);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;

}


.spring_ButtonBox{
    border-radius: 5px;
    /* background-color: #ffffff; */
    width: 55px;
    height: 55px;    
    /* border: 1px solid rgb(215, 213, 213); */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0px 0px 0px;
    
  /* box-shadow: 0px 0px 12px #cdcdcd; */
    
}

.spring_ValBoxBtm{
    margin-top: auto;
    text-align: center;
    font-size: 0.7rem;
    font-weight: 500;
}

.spring_ValBoxIcon{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding-top: 3px;
}

.spring_circleGauge{
    height: 100px;
    width: 100px;
    border-radius: 100px;
    border: 5px solid rgb(167, 128, 128);
}

.spring_acBtmBar{    
    margin-top: auto;
    /* position: absolute;z
    bottom: 0; */
    /* background-color: #75b283; */
    border-top: 1px solid #c6c5c5 ;
    padding: 13px 0px 0px 0px;
}

.spring_acPage{
    /* height: 330px; */
    display: flex;
    flex-direction: column;
    /* background-color: #efa4a4; */
}

.spring_btmBarText{
    font-size: 0.5rem;
    font-weight: 700;
}

.spring_btmBarIcon{
    font-size: 1.2rem;
}

.spring_btmBarBox{
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: -5px; */
    padding-bottom: 5px;
}


.spring_acSettingBar{
    margin-top: auto;
    padding-bottom: 5px;
    /* margin-bottom: -5px; */
    /* padding:40px 0px 5px 0px;   */
}

.spring_butBoxIcon{
    font-size: 0.7rem;
    font-weight: 700;
}

.spring_LedOn{
    height: 15px;
    width: 15px;
    /* border: 3px solid rgb(5, 188, 154) ; */
    background-image: var(--spring_Ind_R_G);
    /* background-image: var(--g2); */
    border-radius: 20px;
    margin-top: 6px;
}
.spring_LedOff{
    height: 15px;
    width: 15px;
    border: 1px solid #cecece ;
    background-image: var(--spring_Ind_Grey_gr);
    /* background-image: var(--g2); */
    border-radius: 20px;
    margin-top: 6px;
}

.spring_AcSetpointBox{
    width: 120px;
    height: 150px;
    /* background-color: #8d5b5b; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 10px 0px;
    margin-left: -20px;
}

.spring_SceneMainContainer{
    width: 150px;
    height: 150px;
    /* background-color: #fafafa; */
    margin-left: -40px;
    margin-right: -10px;
    /* margin-top: 18px;
    margin-bottom: 7px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid var(--grey1); */
    border-radius: 10px;
}
.spring_SceneTable{
    border: 1px solid var(--grey1);
    border-radius: 10px;
    /* height: 110px; */
    padding: 0px 5px 3px 5px;
}

.spring_SceneMainRow{
    /* background-color: #dfdfdf; */
    width: 150px;
    margin-bottom: -3px;
}
.spring_SceneMainTitle{
    font-size: 0.6rem;
    width: 90px;
    font-weight: 500;
    /* background-color: #57de5b; */
}

.spring_SceneMainValue{
    margin-left: auto;
    font-weight: 500;
    width: 50px;
    text-align: end;
    /* background-color: #b2e249; */
}
.spring_SceneMainValue_addVar{
    margin-left: auto;
    font-weight: 500;
    width: 50px;
    text-align: center;
    /* background-color: #b2e249; */
    /* padding-top: -10px; */
    /* height:25px; */
}


.spring_sceneToggleTitle{
    font-size: 0.7rem;
    font-weight: 700;
}
.spring_sceneToggleTitle_ZoomIn{
    font-size: 0.7rem;
    font-weight: 700;
    margin-left: 5px;
}


.spring_CtrlSpBox{
    padding-top: 5px;
    position: absolute;
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: -20px;
}
.spring_CtrlSpValue{
    font-size: 1.5rem;
    font-weight: 500;
}
.spring_CtrlSpUnit{
    padding: 0px 0px 0px 3px;
    font-size: 0.8rem;
    /* font-weight: 500; */
}

.spring_statusOnOffButton{
    border-radius: 50px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 10px;
}

.spring_statusNotOn{
    /* background-image: var(--spring_button_R_G); */
    border: 3px solid var(--btnGreen);
    color: white;
}

.spring_statusOn{
    /* background-image: var(--spring_button_R_G); */
    background-color: rgba(20, 136, 26, 0.5);
    color: white;
}

.spring_statusNotOff{
    /* background-image: var(--spring_button_R_G); */
    border: 3px solid var(--acRed);
    color: white;
}

.spring_statusOff{
    /* background-image: var(--spring_button_R_G); */
    background-color: rgba(180, 31, 5, 0.5);
    color: white;
}

.spring_acOnOffButton{
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 15px -30px 0px 10px;
}

.spring_acOnOffButton_Sce{
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    /* margin: 15px -30px 0px 10px; */
}


.spring_acOnOffContainer{
    /* background-color: #df5050; */
    padding: 0px 25px;
    /* margin-top: -65px; */
}

.spring_acPg{
    /* background-color: #ae2e2e; */
    height: var(--AcTpPgHeight);
    display: flex;
    flex-direction: column;
}
.spring_100Per{
    /* background-color: #ae2e2e; */
    height: 200px;
    display: flex;
    flex-direction: column;
}


.spring_acButtonOn{
    /* background-image: var(--spring_button_R_G); */
    background-color: var(--acGreen);
    color: white;
    
}
.spring_acButtonOff{
    /* background-image: var(--spring_button_Red_Gr); */
    background-color: var(--acRed);
    color: white;
}

.spring_TpChangeTab{
    color: #fff;
    background-color: #0ca346;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 24px;
    border-radius: 6px 0px 0px 6px;
    margin-left: auto;
    margin-right: -10px;
    font-weight: 500;
    font-size: 1.2rem;
}

.spring_ConfirmButtonBox{
    top: 0px;
    background-color: #e8e8e8;
    width:100%;
    height: 73px;
    border-radius: 0px 0px 10px 10px;
    display: flex;    
    border-top: 1px solid #c6c5c5 ;
}

.spring_acConfirmButton{
    height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: white;
    font-weight: 500;
    font-size: 1.1rem;
}
.spring_acConfirm_L{
    /* background-image: var(--spring_button_Red_Gr) ; */
    background-color: var(--acRed);
}
.spring_acConfirm_R{
    /* background-image: var(--spring_button_R_G) ; */
    background-color: var(--acGreen);
}
.spring_acConfirm_Grey{
    /* background-image: var(--spring_button_R_G) ; */
    background-color: var(--grey3);
}

.spring_blinkRed{
    /* background-color: #b62222; */
    /* animation: blink 2s linear infinite; */
    animation: blinker 1s step-start infinite;
}

@keyframes blinker {
    50% {
      /* opacity: 0; */
      background-color: var(--acRed);
      color: white;
    }
}

.spring_blink{
    opacity: 0;
    animation: blinker_1 1s step-start infinite;
}

@keyframes blinker_1 {
    50% {
      opacity: 1;
      /* background-color: var(--acRed);
      color: white; */
    }
}


.spring_sceneTabPage{
    height: 100%;
    /* width: 100%; */
    display:flex;
    flex-direction: column;
    /* background-color: #e67373; */
}

.spring_sceneBar{
    /* background-color: #c12525; */
    margin-top: 10px;
    /* margin-left: 15px; */
    margin-bottom: 5px;
    /* width: 100%; */
    /* overflow: scroll; */
    flex-wrap: wrap;
    overflow-x: scroll;
    /* height: 100px; */
}


.spring_rulesBar{
    margin: 10px 10px;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 5px;

}

.spring_rulesBox{
    /* background-color: #bdbaba; */
    margin: 2px 0px;
    /* justify-content: center; */
    display: flex;
    align-items: center;
}
.spring_actionBox{
    /* background-color: #65e2a4; */
    margin-top: 5px;
}

.spring_ruleFrontText{
    font-size: 0.6rem;
    font-weight: 900;
    margin: 0px 5px -2px 5px;
    width: 45px;
    /* background-color: #cd4a4a; */
    /* color: var(--grey3); */
}

.spring_ruleOpeBox{
    margin: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 20px;
    width: 20px;
}

.spring_ruleSepLine{
    background-color: var(--bgc);
    height: 1px;
    margin: 5px 0px 0px 0px;
}

.spring_SceneTitle{
    font-weight: 500;
    margin-left: 10px;
    /* background-color: #fff; */
    /* text-align: center; */
}

.spring_sceneEditModeBox{
    margin-left: auto;
    /* font-size: 1.1rem; */
    margin-right: 10px;
    /* margin-bottom: -3px; */
    /* background-color: #b83636; */
    display: flex;
    justify-content: center;
    color: var(--blueText);
}

.spring_sceneTitleBox{
    margin: 0px 0px -5px 15px;

}

.spring_ruleEditMode{
    color: var(--blueText);
    text-decoration: underline;
}
.spring_OpeEditBox{
    color: var(--blueText);
    /* border-bottom: 2px solid var(--blueText); */
    background-color: #dfb62e;
    border-radius: 3px;
    width: 20px;
    height: 20px;
}

.spring_OverflowY{
    overflow-y: scroll;
}

.spring_ruleMaxMsg{
    font-size: 0.6rem;
    font-weight: 800;
    color: var(--grey5);
}


.spring_SceneBox{
    width: 60px;
    margin: 5px;
    /* border: 1px solid grey; */
}

.spring_sceneSelIcon{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 5px 0px 0px ;
    margin-bottom: -2px;
    /* background-color: var(--grey1); */
    height: 23px;
    /* border-radius: 10px 10px 0px 0px ; */
    font-size: 0.9rem;
}

.spring_sceneTextBox{
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
}

.spring_sceneTextBox_non{
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    border-radius: 5px;
    padding: 5px;
}

.spring_sceneTextBox_Sel{
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--acGreen);
    /* background-image: var(--spring_button_R_G); */
    color: white;
    border-radius: 5px;
    padding: 5px;
}

.spring_sceneSelName{
    /* height: 80px; */
    text-align: center;
    font-size: 0.68rem;
    font-weight: 600;
    /* overflow-y: hidden; */
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2; 
    -webkit-box-orient: vertical;
    /* background-color: #0ca346; */
}
.spring_ruleSepLine_noGap{
    background-color: var(--bgc);
    height: 2px;
}

.spring_inputTpTopText{
    margin: 50px 0px 30px 0px;
    font-size: 1.1rem;
}
.spring_inputTpMidText{
    font-size: 0.6rem;
    font-weight: 700;
    color: var(--grey6);
}

.spring_ruleVarListBox{
    border: 1px solid var(--grey4);
    border-radius: 30px;
    width: 200px;
    /* height: 30px; */
    text-align: center;
    margin: 5px 0px;
    padding: 3px 15px ;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
}

.spring_editVarBox{
    border: 1px solid var(--grey4);
    border-radius: 30px;
    width: 200px;
    text-align: center;
    margin: 5px 0px;
    padding: 3px 15px ;
    font-weight: 500;    
}

.spring_ruleVarListContainer{
    /* padding-top: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.spring_diaFixHeight{
    margin-top: 20px;
    height: 230px;
    overflow: scroll;
    /* background-color: #d59595; */
}

.spring_opeContainer{
    /* background-color: #20d5b4; */
    display: flex;
    width: 200px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;    
}

.spring_opeBox{
    /* background-color: #c82222; */
    width: 45px;
    height: 45px;
    margin: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 10px;
}

.spring_ruleActionAreaBar{
    margin: 5px 0px;
    width: 200px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    /* align-items: flex-start; */
}

.spring_cleanModeToggle{
    /* background-color: #652727; */
    font-size: 1.2rem;
    margin-left: auto;
}

.spring_rulesEditContainer{
    margin-left: auto;
    /* background-color: #b74242; */
}

.spring_rulesEditIcon{
    margin: 0px 5px;
    /* background-color: #b74242; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.spring_paddingLeft_10{
    padding-left: 10px;
}
/* .spring_scenePgSelBar{

} */

.spring_scenePgSelItem{
    width: 80px;
    height: 25px;
    /* background-color: #fe5353; */
    text-align: center;
    margin-top: 15px;
    font-weight: 500;
    font-size: 0.8rem;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spring_sceneVarBar{
    /* background-color: #d0c9c9; */
    margin: 20px 10px;
}

.spring_sceneVarBox{
    background-color: #f9f9f9;
    /* margin: 5px 10px 5px 5px; */
    margin: 5px;
    padding: 3px 20px;
    border-radius: 20px;
    width: 260px;
}
.spring_sceneVarBar_invalid{
    /* background-color: var(--ErrColorBar); */
    color: var(--ErrColor_Darker);
}

.spring_sceneVarValueBar{
    margin-left: auto;
}
.spring_sceneVarValue{
    font-weight: 500;
}
.spring_sceneVarUnit{
    width: 30px;
    height: 21px;
    margin-left: auto;
    padding-left: 5px;
    font-size: 0.7rem;
    font-weight: 500;

    display: flex;
    align-items: flex-end;
    /* background-color: #67ea8c; */
}

.spring_varEditBox{
    margin-left: auto;
    margin-right: 10px;
    /* background-color: #d46666; */
}

.spring_linkVarName{
    width: 130px;
}

.spring_editLinkVarContainer{
    width: 100%;
    padding: 10px 30px 0px 30px;
}

.spring_editLinkVarselBar{
    /* border: 1px solid rgb(200, 122, 122); */
    border-radius: 5px;
    border: 1px solid var(--grey4);
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    font-weight: 500;
}

.spring_editLinkVar_ItemBox{
    margin: 10px 0px;
}

.spring_sceneVarDelTab{
    background-color: var(--ErrColor_Darker);
    border-radius: 30px;
    padding: 5px 35px;
    width: 240px;
    margin: 15px 0px ;
}

.spring_sceneVarDelText{
    font-size: 0.8rem;
    font-weight: 500;
    color: white;
}

.spring_sceneVarDelIcon{
    color: white;
    margin-left: 10px;
}

.spring_versionNotSupported{
    font-size: 0.6rem;
    top: -3px;
    left: 20px;
}

.spring_scheListPg{
    overflow: scroll;
}

.spring_scheListContainer{
    /* width: 100%; */
    /* background-color: #d34646; */
}

.spring_scheListBar{
    width: 100%;
    background-color: #fff;
    margin: 5px 10px;
    border-radius: 5px;
    padding: 0px 10px;
    display: flex;
}

.spring_greyoutBar{
    background-color: var(--grey1);
}
.spring_scheListName{
    font-size: 0.7rem;
    font-weight: 500;
    /* background-color: #e7c4c4; */
    /* margin: ; */
    margin: 2px 0px -2px 3px;
    /* text-align: center; */
}

.spring_scheListInfo{
    /* background-color: #fc6868; */
    display: flex;
    justify-content: space-between;
}
.spring_scheListTime{
    font-size: 1.5rem;
    /* font-weight: 500; */
    /* background-color: #42c072; */
    flex: 1;
}

.spring_scheListAction{
    /* background-color: #8dc549; */
    flex: 1;
    justify-content: center;
}

.spring_scheListAux{
    /* background-color: #aad625; */
    flex: 1;
    /* justify-content: center; */
    height: 100%;
    align-items: flex-end; 
    justify-content: center;
     /* margin-bottom: -10px; */

}
.spring_scheListSP{
    font-size: 1.5rem;
    /* font-weight: 500; */
    /* background-color: #eb6969; */
}

.spring_scheListScene{
    text-align: center;
    font-size: 1.5rem;
    /* font-weight: 500; */
    width: 130px;
    /* background-color: #32c97b; */
    /* height: 25px; */
    /* margin-bottom: -7px; */
    /* display: flex;
    align-items: flex-end; */
}

.spring_scheListFan{
    font-size: 0.7rem;
    font-weight: 500;
}

.spring_scheListSwing{
    font-size: 0.7rem;
    font-weight: 500;
}

.spring_scheListSmallIcon{
    /* background-color: #bd4cdc; */
    /* margin-left: auto; */
    margin-right: 5px;
}
.spring_scheListIconVal{
    width: 30px;
    /* background-color: #ed2e2e; */
    /* margin-left: auto; */
}

.spring_scheListEdit{
    margin-left: auto;
    margin-right: 10px;
    color: var(--lightBlue);
}

.spring_scheListPriorityIcon{
    margin-left: 10px;
    color: var(--btnGreen);
}

.spring_colorBlue{
    color: var(--lightBlue);
}
.spring_colorYellow{
    color: orange;
}

.spring_scheListEventDate{
    font-size: 0.7rem;
    font-weight: 500;
    /* background-color: #e43333; */
}

.spring_scheListBtmBar{
    /* background-color: #d24242; */
    height: 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 5px;
    /* margin-top: -2px; */
}

.spring_ScheList_DowBox{
    /* border: 2px solid var(--lightBlue); */
    position: relative;
    border-radius: 3px;
    font-size: 0.5rem;
    font-weight: 700;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: var(--lightBlue);
}

.spring_SL_Weekend{
    /* border: 2px solid var(--ErrColorDark_1); */
    color: var(--ErrColor_Darker);
}

/* .spring_SL_Selected{
    border-top: 2px solid var(--acGreen);
} */

.spring_scheListiCtrl{
    right: 68px;
    color: var(--acGreen);
    font-size: 0.7rem;

}

/* .spring_scheListMode{
    font-size: 0.6rem;
    font-weight: 500;
    border: 3px solid var(--acGreen);
    border-radius: 5px;
    padding: 0px 3px;
    margin-right: 5px;
} */

.spring_lightBlue{
    color: var(--lightBlue);
}

.spring_acGreen{
    color: var(--acGreen);
}

.spring_RedDot{
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: var(--acRed);
    top: -2px;
    border-radius: 5px;
}

.spring_scheListIctrlIcon{
    font-size: 0.6rem;
    margin-left: 3px;
    margin-top: -10px;
    /* margin: -10px 3px 0px 3px; */
}

.spring_editScenePg{
    margin: 0px 10px;
    /* background-color: #ca2e2e; */
    overflow: scroll;
}

.spring_editRightItem{
    margin-left: auto;    
    padding-left: 10px; 
}

.spring_editItemBar{
    margin-bottom: 5px;
    /* background-color: red; */
}

.spring_editLeftTitle{
    font-weight: 500;
}

.spring_editIcon{
    font-size: 1.2rem;
    /* background-color: #cd4545; */

}
.spring_scheActionBox{
    /* background-color: #c82222; */
    width: 80px;
    height: 45px;
    margin: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 10px;

    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
}

.spring_editScheGroup{
    background-color: var(--cardWhite);
    padding: 5px 10px 1px 10px;
    border-radius: 10px;
    margin: 10px 0px;
}

.spring_scheDowSelBox{
    border: 3px solid var(--grey1);
    border-radius: 5px;
    /* padding: 0px 5px; */
    margin: 0px 5px;
    width: 25px;
    /* text-align: center; */
    font-weight: 700;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spring_scheDowSelBox_sel{
    background-color: rgb(51, 160, 12);
    border: 3px solid rgb(17, 120, 5);
    color: white;
}

.spring_DowBar{
    /* background-color: #8e2222; */
    padding: 10px 0px;
    /* margin: 5px; */
}


.spring_GreyOutBox{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #9f9f9f;
    opacity: 0.5;
}

.spring_RjSettingGroupTitle{
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: -10px;
    margin-left: 10px;
    color: var(--grey5);
}

.spring_RjSettingGroupTitle_stayRight{
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: -10px;
    margin-left: auto;
    margin-right: 10px;
    color: var(--grey5);
}

.spring_rjRuleFanSwingButton{
    /* background-color: rgb(241, 241, 241); */
    height: 40px;
    width: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey4);
    /* font-weight: 500; */
    color: var(--blueText);
}

.spring_sceIdxText{
    font-size: 0.6rem;
    font-weight: 500;
    /* background-color: #cecece; */
    margin-left: 5px;
    color: var(--grey3);
}

.spring_SceneSaveEeprom{
    border: 1px solid var(--grey3);
    border-radius: 10px;
    padding: 10px;
    width: 180px;
    height: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    font-weight: 500;
    /* background-color: #b71c1c; */
}

.spring_RjSceneChipIcon{
    margin: -2px 5px 0px 5px;
    color: var(--blueText);
}

.spring_RjSceneChipStatus{
    position: absolute;
    left: 47px;
    top: 27px;
    font-size: 0.7rem;
}


.spring_rjLinkVarContainter{
    /* background-color: #e6abab; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -5px 0px 0px 20px ;
    height: 100px;
}
.spring_rjLinkVarBox{
    /* background-color: #94c6a3; */
    margin: 10px 10px 0px 0px;
    width: 88px;    
    border-radius: 5px;
    border: 1px solid var(--grey2);
    padding: 0px 5px;
}
.spring_rjLinkVarTitle{
    font-size: 0.7rem;
    font-weight: 500;
    color: var(--grey5);
}
.spring_rjLinkVarValue{
    margin-top: -5px;
    font-size: 1.1rem;
    font-weight: 500;
}

.spring_rjSceneContainer{
    /* background-color: #dc8a8a; */
    margin-top: 10px;
    height: 100px;
}
.spring_rjSceneButtonText{
    /* background-color: #68ad3b; */
    /* height: 50px;
    width: 150px; */
    display: flex;
    text-align: center;
    align-items: center;
    /* border: 1px solid var(--grey4); */
    border-radius: 8px ;
    padding: 10px;
    font-weight: 500;
    background-color: #fdfdfd;
    margin-top: 2px;
}
.spring_rjSceneButtonCancel{
    color: var(--acRed);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.spring_RjMainPgBtn{
    margin-top: -3px;
}

.spring_rjSwapStateIcon{
    height: 25px;
    width: 25px;
    border-radius: 20px;
    left: 70px;
    top:-6px;
    font-size: 0.7rem;
    background-color: #f2be3a;
}

.spring_rjCleanningIcon{
    top: 32px;
    left: 75px;
    /* color: var(--acGreen); */
}

.spring_RjEmptyVarList{
    /* background-color: #e24747; */
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.spring_infoTitle{
    font-size: 1.2rem;
    font-weight: 500;
}
.spring_infoContent{
    font-size: 0.8rem;
    padding: 10px 40px;
    text-align: center;
}

.spring_sceneDisplayContainer{
    width: 100%;
    height: 200px;
    /* background-color: #f2be3a; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* padding: 0px 20px; */
}

.spring_smallTitleText_Sce{
    padding-left: 5px;
    font-size: 0.6rem;
    font-weight: 700;
    color: var(--acGreen);
}

.spring_SceCancel{
    margin-left: 30px;
    background-color: var(--acRed);
    border-radius: 5px;
    color: white;

    font-size: 0.6rem;
    font-weight: 500;
    padding: 0px 5px;
}

.spring_ScenDisplayContainer{
    /* background-color: #f1c1c1; */
    
    width: 130px;
}

.spring_SceOffBtn{
    margin-bottom: -10px;
    margin-right: 5px;
    /* margin-left: 5px; */
}

.spring_SceCleanBtn{
    margin-bottom: -15px;

}

.spring_SceneButton{
    border: 1px solid var(--grey3);
    border-radius: 20px;
    text-align: center;
    margin: 0px 10px;
    width: 100%;
    /* background-color: #efb4b4; */
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spring_SceneButtonSel{
    border: 1px solid var(--acGreen);
    border-radius: 20px;
    text-align: center;
    margin: 0px 10px;
    width: 100%;
    background-color: var(--acGreen);
    color: var(--cardWhite);
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spring_SceneList{
    /* display: flex;
    align-items: center; */
    padding: 0px 15px;
    margin: 10px 0px;
}

.spring_SceneIdx{
    /* background-color: #70e1ca; */
    text-align: center;
    font-size: 0.8rem;
    color: var(--grey5);
}

.spring_ValidSceneText{
    font-weight: 500;
    /* color: var(--blueText); */
}

.spring_EmptySceneText{
    color: var(--grey3);
}

.spring_uploadSceText_Container{
    margin-top: 60px ;
    margin-bottom: 30px ;
}
.spring_uploadSceText{
    font-weight: 500;
    font-size: 2rem;
}

.spring_scheActiveContainer{
    margin: 10px 40px 10px 40px;
    /* background-color: #c95151; */
    width: 100%;
    font-size: 1.2rem;
    font-weight: 500;
}

.spring_scheActive_Text{

}

.spring_scheActive_Icon{
    margin-left: auto;
}

.spring_sync_Title{
    margin: 35px 0px 20px 0px;
    font-weight: 500;
    font-size: 1.5rem;
}

.spring_sync_desc{
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.spring_sync_button{
    border-radius: 20px;
    background-color: var(--acGreen);
    /* padding: 10px; */
    width: 70px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--cardWhite);
    font-weight: 500;
    margin: 10px;
}

.spring_acRedColor{
    background-color: var(--acRed);
}

.spring_modeText{
    font-size: 0.75rem;
    padding-top: 3px;
}

.spring_SceneTableStrip{
    background-color: #e5e5e5;
    margin-left: -5px;
    padding-left: 5px;
    margin-right: -5px;
    padding-right: 5px;
}
.spring_SceneTableStrip_Last{
    background-color: #e8e8e8;
    margin-left: -5px;
    padding-left: 5px;
    margin-right: -5px;
    padding-right: 5px;
    border-radius: 0px 0px 10px 10px;
}
.spring_acSceneButton{
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 15px -30px 0px 10px;
    font-size: 0.75rem;
}

.spring_SceneToggleBar{
    margin-top: 5px;
    width: 150px;
    /* background-color: #e47171; */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 100px; */
}

.spring_sceneToggleName{
    font-size: 0.85rem;
    font-weight: 700;
    margin-left: 5px;
    color: var(--blueText);
    text-decoration: underline;
    /* background-color: #fff; */
    max-width: 85px;
}
.spring_sceneToggleZoomIn{
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 5px;
    color: var(--blueText);
    text-decoration: underline;
    /* background-color: #fff; */
    max-width: 140px;
    text-align: center;
}

.spring_sceneMin_Internal{
    /* background-color: #8aef99; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: var(--btnGreen);
    /* color: var(--G_Purple);     */
    margin-left: auto;
    /* margin-top: 2px; */
    /* padding-top: 2px; */
    /* top: 10px; */
}

.spring_mainDirBar{
    margin: 7px 0px 0px 0px;
    /* background-color: #51f354; */
    width: 90%;
    /* text-align: center; */
    border-radius: 20px;
    border: 1px solid var(--grey3);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spring_mainDirName{
    background-color: #9f6464;
    /* display: flex; */
    /* width: fit-content; */
}


.spring_dashboard_Contianer{
    display: flex;
    /* justify-content: center; */
    padding: 0px 10px;
    margin-left: 120px;
    flex-direction: column;
    align-items: center;
}



.spring_DashboardMaxWidth{
    /* max-width: 1203px; */
    width: 75%;
}

.spring_DashboardStartingPoint{
    justify-content: flex-start;
}

@media (max-width:1600px) {
    .spring_DashboardMaxWidth{
        /* max-width: 905px; */
        width: 1203px;
    }
}
@media (max-width:1300px) {
    .spring_DashboardMaxWidth{
        /* max-width: 905px; */
        width: 905px;
    }
}
@media (max-width:1000px) {
    .spring_DashboardMaxWidth{
        /* max-width: 605px; */
        width: 605px;
    }
}
@media (max-width:620px) {
    .spring_dashboard_Contianer{
        display: flex;
        /* justify-content: left; */
        align-items: flex-start;
        padding: 0px 10px;
    }    
    .spring_DashboardMaxWidth{
        /* max-width: 305px; */
        width: 305px;
    }
}




.spring_Container_T1{
    /* border: 2px solid rgb(133, 29, 29); */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* background-color: #8b8e7c;   */
}



.spring_Large_Template{
    width: 600px;
    height: 400px;
    border: 1px solid var(--grey2);
    border-radius: 10px;
    /* background-color: #e74b4b; */
}
.spring_Small_Template{
    width: 300px;
    height: 200px;
    border: 1px solid var(--grey2);
    /* background-color: #57e077; */
    border-radius: 10px;
}



.spring_DashGroupTitle{
    /* width: 100%; */
    /* background-color: #db6060; */
    font-weight: 500;
    font-size: 1.7rem;
    /* padding-left: 10px; */
    margin: 25px 5px 0px 30px;
}

.spring_dahsItemSize_400_300{
    height: 400px;
    width: 300px;
    padding: 5px;
    /* background-color: #57e077;  
    border: 1px solid var(--grey2);
    border-radius: 10px;*/
}
.spring_dahsItemSize_200_300{
    height: 200px;    
    width: 300px;
    padding: 5px;
}
.spring_dahsItemSize_400_600{    
    height: 400px;
    width: 600px;
    padding: 5px;
}


.spring_dashItemFrame{
    background-color: var(--cardWhite);
    /* margin: 5px; */
    /* background-color: #0ca346; */
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.spring_dashItemTitleBar{
    background-color: #fcfbfb;
    margin: -5px -10px -5px -10px;
    padding: 5px 10px 5px 10px;
    border-radius: 10px 10px 0px 0px;
}

.spring_dashItemTitle{
    font-size: 1.2rem;
    font-weight: 500;
    /* color: var(--grey6); */
    /* background-color: #cb7171; */
}

.spring_dashItemManuIcon{
    margin-left: auto;
    /* background-color: #33b469; */
    display: flex;
    align-items: center;
}

.spring_floatingMenuContainer {
    position: relative;
}

.spring_floatingMenu {
    position: absolute;
    width: 100px;
    height: 100%;
}

.spring_compFrame {
    margin: 10px 5px;
    padding: 10px;
    /* width: 320px;
    min-height: 420px; */
    /* height: 70px; */
    /* border: 1px solid var(--grey2); */
    border-radius: 10px;
    /* background-color: var(--bgc); */
    background-color: rgb(240, 240, 240);
  
    box-shadow: -8px -8px 8px rgb(255, 255, 255),
      -8px -8px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);
  }

  .spring_idleIndicator{
    
    width: 25px;
    height: 25px;
    margin: 5px;
    border-radius: 15px;
    /* border: 2px solid rgb(185, 181, 181); */
    /* border: 1px solid linear-gradient(135deg, rgb(197, 197, 197), rgb(84, 84, 84)); */
    /* background-color: rgb(189, 5, 5); */
    background-image: linear-gradient(135deg, rgb(212, 210, 210), rgb(165, 163, 163));
}

.spring_greenIndicator{
    width: 25px;
    height: 25px;
    margin: 5px;
    border-radius: 15px;
    /* background-color: rgb(189, 5, 5); */
    background-image: linear-gradient(135deg, rgb(101, 255, 101), rgb(1, 108, 1));
}

.spring_redIndicator{
    width: 25px;
    height: 25px;
    margin: 5px;
    border-radius: 15px;
    /* background-color: rgb(189, 5, 5); */
    background-image: linear-gradient(135deg, rgb(230, 56, 56), rgb(126, 3, 3));
}

.spring_groupStatus {
    margin: 5px 0;
}



.spring_dashItemGreyBox{
    background-color: #c3c3c3;
    opacity: 0.4;
    width: calc(100% + 20px);
    position: absolute;
    margin-left: -10px;
    border-radius: 0px 0px 10px 10px;
    z-index: 2;
}


.spring_dashItemManuContainer{
    background-color: #f7f7f7;
    /* margin-left: auto; */
    right: -10px;
    /* width: 100%; */
    width: 150px;
    /* height: 300px; */
    border-radius: 0px 0px 10px 10px;
    padding: 5px 0px 10px 15px;
    z-index: 3;
}

.spring_dashManuBox{
    background-color: #afafaf;
    height: 100%;
}

.spring_DashButtonStayBtm{    
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-left: -10px;
}


.spring_hisDisplayContainer{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px;
    /* background-color: #d33939; */
    /* padding-top: 100px; */
}
.spring_hisDisplayLayout{
    margin: 15px;
}

.spring_HisTitleBar{
    height: 50px;
    background-color: #e4e4e4;
    padding: 3px 15px 0px 15px;
    /* background-color: #dc3b3b; */
}
.spring_HisTitle{
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: -2px;
}
.spring_HisIconContainer{
    margin-left: auto;
}
.spring_HisIcon{
    margin: 10px;
    background-color: rgb(248, 248, 248);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: white;
}

.spring_hisIcon_Back{
    /* background-color: #be7a14; */
    color: #be7a14;
}
.spring_hisIcon_Exit{
    /* background-color: #ff0101; */
    color: #ff0101;
}
.spring_hisIcon_Sort{
    /* background-color: #3777d5; */
    color: #3777d5;
}

.spring_HisGraphTitleBar{
    padding: 5px 10px 5px 20px;
    margin-bottom: 5px;
}

.spring_HisGraphTitle{
    font-size: 1.2rem;
    font-weight: 500;
    /* background-color: #cd3939; */
    width: 240px;
}

.spring_HisGraph_AddDev{
    /* background-color: #ce6767; */
    width: 100%;
    height: 100%;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spring_HisGraph_AddDevIcon{
    width: 50px;
    height: 50px;
}

.spring_hisSettingTab{
    padding: 5px 5px;
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(232, 232, 232);
    /* background-color: rgb(216, 74, 74); */
}

.spring_dataDownloadSettingTab{
    padding: 5px 5px;
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    margin: 0px 10%;
    /* background-color: rgb(232, 232, 232); */
    /* background-color: rgb(216, 74, 74); */
}

.spring_hisSetTime{
    margin: 0px 2px;
    /* background-color: #c85555; */
}
.spring_hisSetTimeButton{
    margin-left: 2px;
}

.spring_hisTimeName{
    /* background-color: #e88282; */
    width: 50px;
    /* display: flex;
    flex-direction: column; */
    text-align: end;
    
}

.spring_HisSetTimeIconFrame{
    background-color: #109426;
    color: white;
    height: 25px;
    width: 25px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.spring_HisTopStick{
    position: fixed;
    top:0;
    width:100%;
    z-index:2;
}

.spring_DiaSelDev{
    /* background-color: #bebebe; */
    height: 380px;
}

.spring_DiaSelDev_ButtonBar{
    /* background-color: #fff; */
    width: 100%;
    padding: 0px 30px;
    margin-top: 10px;
}

.spring_DiaSelDev_MainContainer{
    /* background-color: #642929; */
    height: 100%;
    width: 100%;
}

.spring_DiaSelDev_DevList{
    padding: 10px 30px 0px 30px;
    /* background-color: #81d08e; */
    height: 248px;
    overflow: scroll;
    /* border: 1px solid black; */
}

.spring_HisGraphCancel{
    margin-left: auto;
    margin-right: 10px;
    /* background-color: var(--ErrColor_Darker); */
    color: var(--ErrColor_Darker);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    /* border-radius: 20px; */
    margin-bottom: -25px;
    font-size: 1.4rem;
}

.spring_DiaChooseObj_Container{
    padding-top: 20px;
}
.spring_DiaChooseObj_Item{
    border: 1px solid var(--grey4);
    width: 220px;
    /* background-color: #c74b4b; */
    padding: 5px;
    border-radius: 20px;
    margin: 5px 0px;
}

.spring_DiaChooseObj_SmallName{
    font-size: 0.6rem;
    font-weight: 500;
    margin-top: -5px;
    margin-bottom: -7px;
    margin-left: 15px;
    color: var(--grey4);
}
.spring_DiaChooseObj_Name{
    margin-left: 15px;
    margin-bottom: -5px;
    /* background-color: #e25151; */
    width: 130px;
}

.spring_DiaChooseObj_Title{
    /* background-color: #a84040; */
    width: 220px;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.spring_DiaChooseObj_ItemContainer{
    height: 258px;
    /* background-color: #f27676; */
    overflow: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;    
    justify-content: flex-start;
    align-items: center;
}

.spring_DiaChooseObj_ActionButton{
    /* background-color: #ce5b5b; */
    margin: 0px 3px;
}
.spring_DiaChooseObj_DelButton{
    margin-left: 10px;
    margin-right: -10px;
}
.spring_DiaChooseObj_EmptyList{
    /* background-color: #dc4c4c; */
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 35%;
    font-size: 1.5rem;
    font-weight: 500;
}

.spring_SL_TabPg{
    /* background-color: #d66161; */
    height: 280px;
    padding: 0px 20px;
    overflow-y: scroll;
}
.spring_SL_TabPg_NonPadding{
    /* background-color: #d66161; */
    height: 280px;
    padding: 0px 20px;
}

.spring_Sl_Slot{
    border: 1px solid var(--grey3);
    border-radius: 10px;
    margin: 7px 0px;
    padding: 3px 10px;
}

.spring_SL_CfgPg_ItemContainer{
    /* background-color: #4ed334; */
    margin-top: 10px;
    height: 240px;
    overflow-y: scroll;
    /* background-color: rgb(238, 235, 235) */
}
.spring_TpBottomBar{
    
    /* background-color: #e1e0e0; */
    display: flex;
    justify-content: space-evenly;
    border-radius: 0px 0px 10px 10px;
    border-top: 1px solid #c6c6c6;
    height: 68px;
}

.spring_TpBottomBar_ItemFrame{
    width: 40px;
    height: 40px;
    text-align: center;
    /* background-color: #daa7a7; */
    margin: 10px;
    border-radius: 10px;
}

.spring_TpBottomBar_ItemFrame_longText{
    width: 50px;
    height: 50px;
    text-align: center;
    /* background-color: #daa7a7; */
    margin: 10px;
    border-radius: 10px;
}

.spring_SL_SelButton{
    border-radius: 5px;
    background-color: #a8410a;
    /* padding: 0px 10px; */
    font-size: 0.8rem;
    font-weight: 500;
    color: white;
    width: 50px;
    text-align: center;
}

.spring_SL_CfgDotsFrame{
    background-color: #f7f7f7;
    padding: 5px;
    margin: 5px 0px;
    border-radius: 5px;
}

.spring_SL_DotsList{
    /* background-color: #d35353; */
    overflow-y: scroll;
    height: 230px;
}

.spring_tpContentGroup{
    /* border: 1px solid red; */
    border-radius: 10px;
    margin-bottom: 5px;
    padding: 5px 10px;
    background-color: #fafafa;
}

.spring_tpContent{
    margin: 3px 0px;
}
.spring_tpContentValue{
    margin-right: 10px;
}
.spring_tpContentTitle{
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 10px;
}
.spring_TpBtmButtonTitle{
    font-size: 0.6rem;
    font-weight: 500;
    margin-top: -2px;
}
.spring_chSelFrame{
    border: 3px solid var(--grey1);
    font-size: 0.8rem;
    /* padding: 0px 4px; */
    margin-left: 5px;
    border-radius: 5px;
    width: 20px;
    text-align: center;
}
.spring_chSelFrame_Active{
    background-color: var(--acGreen);
    color: white;
    border: 3px solid green;
    font-weight: 500;
}
.spring_tpContentSendButton{
    border-radius: 20px;
    background-color: var(--acGreen);
    color: white;
    text-align: center;
    /* margin-top: 5px; */
    margin: 15px 2px 0px 2px;
    padding: 5px 0px;
}
.spring_FbSelButton{
    background-color: var(--lightBlue);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    color: white;
    font-size: 0.8rem;
}

/* .spring_ConfirmButtonBox_InsideChild{
    top: 30px;
    background-color: #e8e8e8;
    width:100%;
    height: 73px;
    border-radius: 0px 0px 10px 10px;
    display: flex;    
    border-top: 1px solid #c6c5c5 ;
} */

.spring_acConfirmButton_Lower{
    height: 35px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: white;
    font-weight: 500;
    font-size: 1.1rem;
}

.spring_editScheGroup_SmallSpaceContainer{
    margin: 10px 0px;
    /* background-color: #cd3c3c; */
}
.spring_editScheGroup_SmallSpace{
    background-color: var(--cardWhite);
    padding: 5px 10px 1px 10px;
    border-radius: 10px;
    margin: 5px 0px;
}

.spring_ManuTitle{
    font-weight: 500;
    font-size: 1.2rem;
    /* background-color: #b21313; */
    padding-bottom: 2px;
    margin-left: 5px;
}

.spring_ManuTitleBackIcon{
    font-size: 1.2rem;
    /* background-color: #b21313; */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spring_StatusBar_Grey{
    border-radius: 5px;
    height: 10px;
    width: 100%;
    background-color: #bccac8;
}

.spring_StatusBar_Green{
    border-radius: 5px;
    height: 10px;
    /* width: 80%; */
    background-color: #5de1d4;
}
.spring_StatusBar_Yellow{
    border-radius: 5px;
    height: 10px;
    /* width: 80%; */
    background-color: #ea8d30;
}
.spring_StatusBar_Red{
    border-radius: 5px;
    height: 10px;
    /* width: 80%; */
    background-color: #f11f1f;
}

.spring_fullButton_Green{
    background-color: var(--btnGreen);
    border-radius: 30px;
    text-align: center;
    color: white;
    font-weight: 500;
    padding: 3px 0px;
    margin: 15px 5px;
}

.spring_rjFilterSel{
    border: 2px solid var(--grey2);
    border-radius: 30px;
    padding: 0px 11px;
    margin: 5px;
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--gre5);
}

.spring_rjFilterSel_nonMargin{
    border: 2px solid var(--grey2);
    border-radius: 30px;
    padding: 0px 11px;
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--gre5);
}

.spring_rjFilterSelected{
    border: 2px solid var(--btnGreen);
    background-color: rgb(18, 169, 18);
    color: white;
}

.spring_filterTextArea{
    border: none;
    outline: none;
    resize: none;
    width: 100%;
    background-color: var(--cardWhite);
    color: var(--blueText);
}

.spring_filterTextArea::placeholder{
    color: var(--grey2);

}

.spring_filterLvlSelContainer{
    margin-bottom: 5px;
}   

.spring_filterCleanContainer{
    bottom: -5px;
    /* background-color: #e14c4c; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
}

.spring_filterCleanButton{
    /* background-color: var(--acRed);
    color: white; */
    padding: 0px 10px;
    color: var(--acRed);
    border-radius: 10px;
}

.spring_maintLogContainer{
    background-color: var(--cardWhite);
    padding: 5px 10px 5px 10px ;
    border-radius: 10px;
    margin-bottom: 10px;
}

.spring_maintLog_DT{
    font-size: 0.7rem;
}

.spring_maintLog_Title{
    font-size: 1.1rem;
    font-weight: 500;
    /* background-color: #dd5454; */
}

.spring_maintLog_RT{
    width: 90px;
    /* background-color: #b01616; */
}

.spring_mainLog_SmallTitle{
    font-size: 0.7rem;
    font-weight: 500;
    color: var(--grey4);
}

.spring_mainLog_RemarkContainer{
    /* background-color: rgb(212, 163, 163); */
    border-radius: 2px;
}

.spring_mainLog_Remark{
    font-size: 0.8rem;
    color: var(--grey5);

}

.spring_maintLog_TitleContainer{
    margin: 5px 0px 5px 0px;
    /* background-color: #ac1919; */
}

.spring_ddIcon{
    /* margin: 10px; */
    /* background-color: rgb(248, 248, 248); */
    background-color: var(--bgc);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    /* border-radius: 10px; */
    color: var(--grey6);
}

.spring_hideOverflowText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spring_ddYearMonthInput {
    background-color: var(--cardWhite);
    margin-left: 10px;
    flex: content;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--grey5);
  }

  
.spring_tabContainer {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* justify-content: center; */
    overflow-x: auto;
    white-space: nowrap;
    /* background-color: #a8410a; */
}

.spring_ddPgArrow {
    /* height: 40px; */
    /* display: flex; */
    display: none;
    /* justify-content: center;
    align-items: center; */
    width: 10vw;
    /* text-align: center; */
    /* margin: 15px 10px; */
    /* padding: 0 20px; */
}

@media (max-width: 500px) {
    .spring_tabContainer {
      justify-content: unset;
    }

    .spring_ddPgArrow {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
    
.spring_ddPgSelItem{
    /* width: 100vw; */
    /* display: inline-block; */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 40px;
    /* background-color: #fe5353; */
    text-align: center;
    margin: 15px 10px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 25px;
}


.spring_selectedTab {
    color: var(--grey7);
    border: 1px solid var(--grey7);
}

.spring_downloadText{
    color: var(--btnGreen);
    text-decoration: underline;
}

.spring_italicStyle {
    font-style: italic;
}

.spring_stayTopWhenScroll {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: var(--bgc);
}

.spring_ddList_scroll {
    max-height: 60vh;
    overflow: scroll;
}

.spring_linkButton {
    background: none;
    border: none;
    color: var(--blueText);
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
    font-style: italic;
    font-weight: 500;
}

.spring_leaderTitle {
    font-size: 1.2rem;
    font-weight: 500;
    /* margin-left: 15px; */
}

.spring_storageModeButton {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    padding: 1px;
}