.gapOntopTable {
  margin-top: 15px;
}

.tableContainerFullWidth {
  overflow-y: scroll;
  width: 100%;
  border: 1px solid var(--grey4);
  border-radius: 4px;
  /* background-color: var(--gc1); */
}

.tableItem {
  padding: 3px 0px 2px 10px;
}

.tableItemSelected {
  background-color: var(--grey2);
}
