.selDevBox {
  margin: 20px 10% 0px 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgb(158, 157, 157);
  padding: 30px;
}

.selDevBox:hover {
  cursor: pointer;
}

.divisionContainer {
  /* display: flex; */
  /* justify-content: center; */
}

.divisionTitle {
  /* background-color: rgb(204, 57, 57); */
  padding: 0px 0px 8px 0px;
  margin-left: 10%;
  position: relative;
  top: 10px;
  font-weight: 500;
}
