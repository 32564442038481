.wrapTextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.textLength_80P {
  width: 80%;
  /* background-color: rgb(241, 33, 33); */
}

.maxtLength_80P {
  max-width: 80%;
  /* background-color: rgb(241, 33, 33); */
}

.textLength_70P {
  width: 70%;
}

.spring_textShiftUp{
  padding-top: -2px;
}

.spring_diaItemContianer{
  /* height: 30px;
  background-color: rgb(206, 23, 23); */
  margin: 3px 0px ;
}
