.form-label {
  display: block;
  text-transform: uppercase;
  font-size: small;
  margin-top: 25px;
}

.form-input {
  width: 70%;
  background: transparent;
  border-radius: 5px;
  border: 1px solid;
  padding: 3px 3px 3px 3px;
}

.form-error {
  font-size: 12px;
  color: rgb(184, 97, 97);
  position: absolute;
}

.form-error-center {
  font-size: 12px;
  color: rgb(184, 97, 97);
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.form-container {
  width: 100%;
  /* background-color: rgb(55, 139, 218); */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.form-title {
  text-align: center;
  /* color: rgb(89, 115, 187); */
  margin-top: 5%;
  font-size: 2rem;
  font-weight: 450;
}

.form-card {
  width: 100%;
  /* margin-left: 15%; */
  /* justify-content: center; */
  /* background-color: rgb(55, 139, 218); */
}

.form-button {
  margin-top: 30px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
  background-color: rgb(89, 115, 187);
  padding: 7px 25px 7px 25px;
  color: rgb(243, 239, 239);
}

.form-btm {
  text-align: center;
  margin-top: 30px;
}

.form-inlink {
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: underline;
  color: rgb(89, 115, 187);
}

.email-validation {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
}

.form-recaptcha {
  margin-top: 40px;
}


.form_frame{
  margin: 50px 10% 0px 10%;
  padding-bottom: 50px;
  /* display: flex; */
  /* justify-content: center; */
}
