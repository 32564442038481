.tpSelList {
  background-color: rgb(240, 47, 47);
  margin: 3px;
}

.selListPage {
  background-color: rgb(47, 240, 143);
  height: 100%;
  width: 100%;
  position: absolute;
  /* top: 0;
  left: 0; */
}
