.tpDevice_container {
    padding: 5px 10px 0px 20px;
    border-radius: 10px 10px 0 0;
}

.tpDevice_title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: -3px 0px 0px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.tpDevice_subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tpDevice_subCont {
    width: 90%;
}

.tpDevice_indicator {
    font-size: 0.9rem;
}

.tpDevice_offline {
    font-size: 0.7rem;
    margin-top: -7px;
    color: var(--ErrColor_Darker);
    /* background-color: #1fd741; */
    /* height: 10px; */

}



.tpDevice_emptyDiv {
    height: 25px;
}

.tpDevice_icons {
    margin-left: 10px;
}

.tpDevice_subtitleFrame{
    width: 100%;
    /* background-color: #cb2d2d; */
    padding-left: 16px;
}

.teDevice_SensorSelBox{
    position: absolute;
    top: -15px;
    left: -16px;
    font-size: 1.7rem;
    /* color: rgb(197, 197, 197) */
    color: rgb(187, 187, 187)
}
.teDevice_SensorSelBox_Selected{
    position: absolute;
    top: -22px;
    left: -16px;
    font-size: 1.7rem;
    color: rgb(214, 54, 54)
    /* color: rgb(211, 211, 211); */
}

.teDevice_devSelIndicator{
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: rgb(214, 54, 54);
}
.teDevice_devSelIndicatorLoc{
    position: absolute;
    top: -3px;
    right: -3px;
}

.teDevice_devSelIndicatorBarLoc{
    position: absolute;
    top: 8px;
    right: -15px;
}