.bdOvvName {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--titleColor);
  width: 80%;
  align-self: center;
  text-align: center;
}

.bdContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 17.5px;
  /* background-color: rgb(40, 194, 91); */
}

.dummyContainer {
  height: 0px;
  width: 200px;
  margin: 0px 10px;
}

.BdOvvContainer {
  /* border: 1px solid var(--grey5); */
  /* border-radius: 5px; */
  margin: 5px 5px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* width: 150px; */
  /* background-color: rgb(37, 160, 190); */
}

.BdOvvContainer:hover {
  cursor: pointer;
}

.bdOvvStatusContainer {
  /* background-color: rgb(211, 37, 37); */
  display: flex;
  /* align-items: flex-end; */
  width: 40px;
  margin: 0px 2px;
  justify-content: center;
}

.bdOvvIcon {
  color: var(--bdOvvIconColor);
  font-size: 1rem;
  /* margin: 0px 2px; */
  /* color: red; */
}



.devOvvStatusContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  /* background-color: rgb(219, 27, 27); */
}

.devOvvEachStatus {
  /* width: 40px; */
  margin: 0px 15px;
  /* background-color: rgb(216, 33, 33); */
}

.bdOvvStatusCnt {
  width: 10px;
  margin-left: 10px ;
  /* margin: 0px 5px; */
  text-align: left;
  color: rgb(70, 187, 187);
  /* color: rgb(63, 45, 136); */
  font-size: 0.9rem;
  /* background-color: rgb(122, 96, 238); */
}
/* .bdOvvStatusCnt {
  font-size: 0.6rem;
  margin-top: auto;
  width: 20px;
  text-align: center;
} */