.graph-iconstyle {
    margin: 0 5px 0 5px;
}

.graph-iconstyle:hover {
    cursor: pointer;
}

.icon-deselect {
    color: var(--grey3);
}

.devOvv-iconStyle {
    /* width: 15px;
    height: 15px; */
    margin: -2px 8px 0 5px;
}

.devOvv-iconStyle:hover {
    cursor: pointer;
}

.trueFalseButton{
    /* background-color: rgb(209, 31, 31); */
    border-radius: 5px;
    width: 100px;
    color: white;
}

