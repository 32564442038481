:root {
  --dialogBoxWidth:300px;
  --featureManuHeight:200px;
  --AcTpPgHeight:280px;

  --grey1: #dbdbdb;
  --grey2: #c5c5c5;
  --grey3: #a7a7a7;
  --grey4: #979797;
  --grey5: #7e7e7e;
  --grey6: #696969;
  --grey7: #535353;
  --grey8: #414141;
  --grey9: #353535;

  --btnGreen: rgb(20, 136, 26);
  --btnBlue: rgb(38, 71, 189);
  --lightBlue: rgb(44, 134, 198);

  --titleColor: var(--grey6);
  --favourite: rgb(252, 207, 10);
  --darkYellow: rgb(235, 193, 10);
  --orange: rgb(243, 121, 6);
  --bdOvvIconColor: var(--grey5);
  --bgc: rgb(237, 237, 237);
  /* --bgc: rgb(237, 237, 237); */
  --headerColor: rgb(233, 233, 233);
  --unchangeBox: rgb(227, 227, 227);

  --cardWhite: rgb(247, 247, 247);
  --cardTitleWhite: rgb(243, 243, 243);

  --whiteText: rgb(247, 247, 247);
  --blueText: rgb(9, 63, 212);
  --darkText: var(--grey8);

  --ErrColor: rgb(209, 33, 2);
  --ErrColor_Darker: rgb(180, 31, 5);
  --ErrColorDark_1: rgb(255, 115, 91);
  --ErrColorBar: rgb(255, 202, 193);
  --WarnColor: rgb(182, 179, 7);
  --HealthColor: rgb(2, 177, 46);

  --G_Cyan: rgb(17, 202, 202);
  --G_Purple: rgb(123, 78, 228);

  --greyoutText: var(--grey3);

  --acRed:#cf4444;
  --acGreen: #50c714;

  --gLogo: linear-gradient(30deg, #514CA0 0%, #38C2D9 100%);
  --g2: linear-gradient(to top, #00ffed 0%, #00b8ba 100%);
  --g1: linear-gradient(to top, #ff94b2 0%, #7d77ff 100%);
  --g3: linear-gradient(to top, #ffcf1b 0%, #ff8b1b 100%);
  --g4: linear-gradient(to top, #88cdf6 0%, #015c92 100%);
  --g5: linear-gradient(to top, #f35651 0%, #bf263c 100%);
  --g6: linear-gradient(to top, #79096c 0%, #00d4ff 100%);
  --g7: linear-gradient(to top, #09793c 0%, #00ff2c 100%);
  --g8: linear-gradient(to top, #56c914 0%, #1999d7 100%);
  --g9: linear-gradient(to top, #1914c9 0%, #d110d2 100%);
  --g10: linear-gradient(to top, #172c53 0%, #10d28d 100%);

  --h2: linear-gradient(to right, #00ffed 0%, #00b8ba 100%);
  --h1: linear-gradient(to right, #ff94b2 0%, #7d77ff 100%);
  --h3: linear-gradient(to right, #ffcf1b 0%, #ff8b1b 100%);
  --h4: linear-gradient(to right, #88cdf6 0%, #015c92 100%);
  --h5: linear-gradient(to right, #f35651 0%, #bf263c 100%);
  --h6: linear-gradient(to right, #79096c 0%, #00d4ff 100%);
  --h7: linear-gradient(to right, #09793c 0%, #00ff2c 100%);
  --h8: linear-gradient(to right, #56c914 0%, #1999d7 100%);
  --h9: linear-gradient(to right, #1914c9 0%, #d110d2 100%);
  --h10: linear-gradient(to right, #172c53 0%, #10d28d 100%);

  --spring_button_R_G : linear-gradient(135deg, rgb(85, 255, 97), rgb(4, 107, 11));
  --spring_button_Red_Gr : linear-gradient(135deg, rgb(255, 113, 74), rgb(133, 32, 23));
  --spring_Ind_R_G : linear-gradient(135deg, rgb(85, 255, 252), rgb(2, 146, 120));
  --spring_Ind_Grey_gr : linear-gradient(135deg, rgb(218, 218, 218), rgb(187, 187, 187));
}

.chartColorLine_1 {
  background-color: rgb(39, 218, 164);
}
.chartColorLine_2 {
  background-color: rgb(141, 228, 28);
}
