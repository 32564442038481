.divRelative{
  position: relative;
}
.divAbsolute{
  position: absolute;
}

.reactionMiddleTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  margin: 20px 0px;
}

.titleContainer {
  margin: 40px 50px 50px 50px;
  position: relative;
}

.reactCfgCardTitle_out {
  padding: 0px 10px;
  margin: 20px 10px -5px 10px;
  font-size: 1.2rem;
  /* background-color: rgb(143, 13, 13); */
}

.contentCard {
  padding: 3px 10px;
  margin: 10px 10px;
  background-color: var(--cardWhite);
  border-radius: 10px;
}

.reactCfgContentItem {
  margin: 10px 0px;
  width: 100%;
  /* background-color: rgb(221, 33, 33); */
}

.reactCfgSelTab {
  margin: 0px 0px 3px 0px;
  text-align: center;
  padding: 3px;
  width: 100px;
}

.reactCfgSelTab:hover {
  cursor: pointer;
}

.reactCfgSelTabSelected {
  border-radius: 10px;
  background-color: var(--HealthColor);
  color: var(--whiteText);
}

.reactCfgContentTitle {
  margin: 10px 0px 10px 0px;
}

.reactCfgToggleButton {
  font-size: 1.5rem;
}

.reactCfgButtonOn {
  color: var(--btnGreen);
}

.reactCfgButtonOff {
  color: var(--grey5);
}

.listSelBar {
  border-radius: 20px;
  background-color: var(--cardWhite);
  text-align: center;
  margin: 10px 30px;
  padding: 10px 10px;
}

.reactCfgBarselected {
  background-color: var(--HealthColor);
  color: var(--whiteText);
}

.reactCfgSelGroupTitle {
  margin: 20px 0px -5px 50px;
  color: var(--titleColor);
  font-size: 1.2rem;
  font-weight: 500;
}

.reactCfgSensorPara {
  font-size: 0.8rem;
  color: var(--blueText);
}

.smallContentTitle {
  font-size: 0.7rem;
  color: var(--titleColor);
  /* font-weight: 500; */
  /* background-color: rgb(187, 16, 16); */
  /* text-align: center; */
  /* text-decoration: underline; */
}

.smallSelContentTitle {
  font-size: 0.7rem;
  color: var(--titleColor);
  padding: 5px 0px 0px 0px;
  /* font-weight: 500; */
  /* background-color: rgb(187, 16, 16); */
  text-align: center;
  /* text-decoration: underline; */
}

.reactCfgSmallRemark {
  font-size: 0.7rem;
  color: var(--HealthColor);

  padding: 3px 10px;
  margin: -10px 10px;
}

.contentTitle_L {
  /* font-size: 1.2rem; */
  padding: 0px 5px;
  display: flex;
  justify-content: center;

  /* background-color: rgb(199, 22, 22); */
}

.reactCfgContentButton {
  padding: 0px 5px;
  background-color: var(--btnGreen);
  border-radius: 5px;
  color: var(--whiteText);
}

.reactCfgViewOnlyBar {
  background-color: var(--unchangeBox);
  height: 2rem;
}

.reactCfgFormulaLoadBar {
  background-color: var(--cardWhite);
  margin: 10px 20px;
  border-radius: 10px;
  padding: 10px 10px;
}

.reactCfgAddNewForTab {
  padding: 15px 10px;
  font-weight: 500;
  /* border: 1px solid var(--blueText); */
  /* background-color: var(--btnGreen); */
  /* color: var(--blueText); */
}

.boldText{
  font-weight: 500;
}


.blueText {
  color: var(--blueText);
}
.darkText {
  color: var(--darkText);
}

.greenText {
  color: var(--btnGreen);
}
.redText {
  color: var(--ErrColor_Darker);
}
.yellowText {
  color: var(--darkYellow);
}
.orangeText {
  color: var(--orange);
}
.whiteText{
  color: var(--headerColor);
}

.text_0_9{
  font-size: 0.9rem;
}
.text_0_8{
  font-size: 0.8rem;
}
.text_0_7{
  font-size: 0.7rem;
}

.text_1_1{
  font-size: 1.1rem;
}

.text_1_2{
  font-size: 1.2rem;
}

.text_1_3{
  font-size: 1.3rem;
}

.text_1_5{
  font-size: 1.5rem;
}

.text_2_5{
  font-size: 2.5rem;
}
.text_3{
  font-size: 3rem;
}
.cfgReactEditButtonGroup {
  display: flex;
  /* background-color: rgb(221, 38, 38); */
  width: 20%;
  justify-content: space-evenly;
}

.cfgReactInputErr {
  background-color: var(--ErrColorBar);
}

.reactCfgAlgoFrame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  height: 300px;
  width: 100wh;
  border: 2px solid var(--grey3);
  border-radius: 10px;
  margin: 10px;
  padding: 5px 10px;
}

.rCfgVar {
  border: 2px solid rgb(8, 139, 19);
  background-color: rgb(44, 184, 16);
  border-radius: 5px;
  padding: 2px 10px;
  margin: 5px;
  color: var(--whiteText);
}

.rCfgAlgoEditFrame {
  width: 100wh;
  min-height: 52px;
  border: 2px solid var(--grey3);
  border-radius: 10px;
  margin: 10px;
  padding: 5px 10px;
}

.rCfgOpInsertButton {
  border-radius: 5px;
  background-color: var(--HealthColor);
  border: 2px solid var(--btnGreen);
  color: var(--whiteText);
  padding: 5px 10px;
  margin: 10px;
  /* width: 50px; */
  min-width: 50px;
  font-weight: 500;
}

.rCfgOpInsertButton_ErrColor {
  background-color: var(--ErrColor);
  border: 2px solid var(--ErrColor_Darker);
}

.felx2Center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rCfgOpButtonContainer {
  margin: 5px 5px;
}

.algoFormulaContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.algoDivOp {
  background-color: var(--grey1);
  color: var(--blueText);
  margin: 5px 3px;
  padding: 2px 5px;
  border-radius: 3px;
}

.algoDivCondi {
  background-color: var(--favourite);
  /* font-size: 3rem; */
}

.rCfgTrueFalseSel {
  width: 50px;
  text-align: center;
  color: var(--HealthColor);
  font-weight: 500;
}

.algoFramePlaceholder {
  margin-top: 5px;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: var(--greyoutText);
}

.rCfgContentPad {
  padding: 0px 0px 0px 7px;
  /* background-color: aquamarine; */
}

.rCfgMargin {
  margin: 20px 0px;
}

.rCfgContactColorFrame {
  color: var(--G_Cyan);
}

.rCfgGroupColorFrame {
  color: var(--G_Purple);
}

.rCfgFullSizeSperadButton {
  background-color: var(--cardWhite);
  margin: 10px 10px;
  border-radius: 10px;
  padding: 5px 10px;
}

.rCfgTinyText {
  font-size: 0.6rem;
}

.contentCardPadding {
  padding: 10px;
}

.progressDot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  border: 3px solid var(--grey4);
  background-color: var(--bgc);
  z-index: 2;
}

.currDot {
  /* background-color: var(--HealthColor); */
  border: 5px solid var(--btnGreen);
  background-color: var(--bgc);
}

.passDot {
  background-color: var(--btnGreen);
  border: 2px solid var(--btnGreen);
}

.progressLine {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3px;
  width: 50px;
  margin-top: 9px;
  background-color: var(--grey4);
  z-index: 1;
  /* padding-bottom: 10px; */
}

.passLine {
  /* height: 4px;
  margin-top: 8px; */
  background-color: var(--btnGreen);
}

.progFlowContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 20px;
  /* width: 100%; */
}

.progFlowSContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 20%; */
}

.progLineContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.outsideLineContainer {
  /* position: absolute; */
  top: 5px;
}
