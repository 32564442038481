.mainContent {
  background-color: var(--bgc);
  color: var(--grey6);
  margin-left: 0px;
  padding-bottom: 20px;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--cardWhite) inset !important;
}

