.cbTick {
    position: absolute;
    /* width: 15px;
    height: 15px; */
    /* background-color: blue; */
}

.cbContainer {
    position: relative;
    width: 15px;
    height: 15px;
    border: 1px solid var(--grey8);
    margin: 5px 8px 0 5px;
    /* background-color: blueviolet; */
}