.devSummaryContainer {
  width: 100%;
  /* background-color: rgb(196, 252, 44); */

  /* flex-direction: column; */
}

.ds_iconSec {
  /* background-color: rgb(224, 42, 42); */
  display: flex;
}

.flexVer {
  /* background-color: rgb(89, 230, 84); */
  display: flex;
  flex-direction: column;
}

.flexHorWrap {
  /* background-color: rgb(124, 44, 230); */
  /* border: 1px solid; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexHorWrapContent {
  /* background-color: rgb(89, 230, 84); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 50%;
  /* width: 50%; */
}

.flexWrap {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex: 1 0 50%;
}

.flexDirVer {
  flex-direction: column;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.minTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  margin-bottom: 10px;
  color: "#777877";
}

.nodeIcon {
  width: 45px;
  height: 45px;
}

.statusIcon {
  font-size: 14px;
  margin-right: 8px;
  color: #676867;
  fill: #676867;
}

.statusDisplay {
  font-size: 13px;
  color: #aaaaaa;
}

.displayName {
  font-size: 9px;
  margin-bottom: -3px;
  /* margin-left: 10px; */
  color: #bcbcbc;
  margin-top: 10px;
}

.displayValue {
  font-size: 15px;
  color: #333333;
  text-align: center;
}

.onoff {
  margin-top: 10px;
  color: cyan;
  font-size: 15px;
}
