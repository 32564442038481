.buttonContainer {
  display: flex;
  justify-content: center;
  /* background-color: rgb(202, 69, 69); */
}

.roundButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 100px;
  background-color: var(--btnGreen);
  border-radius: 50px;
  text-align: center;
  color: azure;
  padding: 5px;
  font-weight: 500;
  /* font-size: 1.1rem; */
}

.buttonWithIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -10px;
}

.buttonSpreadEvenly {
  justify-content: space-evenly;
}

.roundButton:hover {
  cursor: pointer;
  background-color: rgb(26, 100, 29);
}

.floatButton {
  position:fixed;
  right: 10px;
  bottom: 10px;
  height: 38px;
  width: 150px;
  background-color: var(--btnGreen);
  color: azure;
  border-radius: 50px;
  text-align: center;
  padding: 5px;
  font-weight: 500;
}

.floatButton:hover {
  cursor: pointer;
  background-color: rgb(26, 100, 29);
}

.toggleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid var(--grey8); */
  height: 38px;
  width: 100px;
  text-align: center;
  padding: 5px;
  font-weight: 500;
  margin: 0 5px;
}

.toggleButton:hover {
  cursor: pointer;
  /* border-radius: 10px; */
  /* background-color: var(--bgc); */
  /* background-color: rgb(240, 240, 240);

  box-shadow: -3px -3px 3px rgb(255, 255, 255),
  -3px -3px 3px rgba(255, 255, 255, 0.5),
  inset 3px 3px 1px rgba(255, 255, 255, 0.1), 3px 3px 8px rgba(0, 0, 0, 0.15); */
}

.selected {
  border-radius: 20px;
  /* background-color: var(--bgc); */
  background-color: rgb(240, 240, 240);

  box-shadow: -3px -3px 3px rgb(255, 255, 255),
    -3px -3px 3px rgba(255, 255, 255, 0.5),
    inset 3px 3px 1px rgba(255, 255, 255, 0.1), 3px 3px 8px rgba(0, 0, 0, 0.15);
}

.roundButtonSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 70px;
  border-radius: 50px;
  background-color: var(--btnGreen);
  text-align: center;
  color: azure;
  padding: 5px;
  font-weight: 500;
  font-size: 0.8rem;
}

.roundButtonSmall:hover {
  cursor: pointer;
  background-color: rgb(26, 100, 29);
}

.roundButton_purple {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 150px;
  background-color: var(--G_Purple);
  border-radius: 50px;
  text-align: center;
  color: azure;
  padding: 5px;
  font-weight: 500;
  /* font-size: 1.1rem; */
}

.roundButton_purple:hover {
  cursor: pointer;
  background-color: rgb(72, 38, 112);
}

.disableClick {
  cursor: not-allowed;
}