.spaceBothSide {
  margin: 0px 35px;
}

.seperationLine {
  background-color: var(--grey5);
  height: 1px;
  width: 100%;
}

.stayRight {
  margin-left: auto;
}

/* .errMsgContain {
  position: absolute;
  font-size: 0.7rem;
  color: var(--ErrColor);
}

.errMsgContainer {
  position: relative;
} */
