.tpDialogClose {
    font-size: 2rem;
    margin: 15px 0;
    color: var(--ErrColor_Darker);
}

.tpDialogClose:hover {
    cursor: pointer;
}

.tpDialogFrame {
    margin: 10px 0px;
    /* padding: 10px 0; */
    width: 300px;
    height: 500px;
    /* border: 1px solid var(--grey2); */
    border-radius: 10px;
    /* background-color: var(--bgc); */
    background-color: rgb(240, 240, 240);
  
    box-shadow: -8px -8px 8px rgb(255, 255, 255),
      -8px -8px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);

    will-change: transform;
}

.tpDialogFrame_small {
    /* padding: 10px 15px; */
    padding: 15px 20px;
    width: 300px;
    height: 380px;
    /* border: 1px solid var(--grey2); */
    border-radius: 10px;
    /* background-color: var(--bgc); */
    background-color: rgb(240, 240, 240);
  
    box-shadow: -8px -8px 8px rgb(255, 255, 255),
      -8px -8px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 4px rgba(255, 255, 255, 0.1), 8px 8px 12px rgba(0, 0, 0, 0.15);
}

.tpParamSel {
    margin: 15px 10px;
}

.tpParamSel_clickable:hover {
    cursor: pointer;
}

.tpDialog_unit {
    /* margin-left: auto; */
}

.tpDialog_checked {
    margin: 0 15px 0 5px;
    /* padding: 0 3px; */
    font-size: 0.8rem;
    border: 2px solid var(--grey3);
    color: var(--btnGreen);
    width: 20px;
    height: 20px;
}

.tpDialog_name {
    font-size: 1.3rem;
    font-weight: 500;
}

.tpDialog_small {
    font-size: 0.8rem;
    margin-top: -5px;
}

.tpDialog_empty {
    /* width: 20px;
    height: 20px; */
}

.tpDialog_greyFill {
    width: 20px;
    height: 20px;
    margin: 0 15px 0 5px;
    background-color: var(--grey2);
}

.tpParamContainer {
    height: 360px;
    overflow: auto;
}

.tpDialog_content {
    width: 80%;
}

.tpDialog_minmaxContainer {
    margin: 20px 30px;
    text-align: center;
}

.tpDialog_minmax {
    /* margin: 0 10px 0 0; */
    font-size: 0.8rem;
}

.tpDialog_buttonCont {
    margin: 30px 0;
}

.tpDialog_button_1 {
    margin: 20px 0 20px 0;
}

.tpDialog_input {
    width: 150px;
    /* background-color: rgb(233, 62, 62); */
    outline: none;
    border: 1px solid var(--grey3);
    border-radius: 10px;
    text-align: center;
    /* border: none; */
    /* border-bottom: 1px solid var(--grey4); */
    background-color: var(--cardWhite);
    color: var(--blueText);
    font-size: 1.2rem;
  }
  
  .tpDialog_title {
    font-size: 1.3rem;
    font-weight: 500;
    margin-right: 10px;
  }

  .tpDialog_subtitle {
    margin: 10px 0 0 0;
    text-align: center;
    font-size: 1.1rem;
  }

  .tpDialog_closeBtn {
    position: absolute;
    height: 43px;
    width: 43px;
    border-radius: 30px;
    /* background-color: brown; */
    margin: -38px 0 0px 260px;
    color: var(--cardWhite);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tpDialog_closeBtn:hover {
    cursor: pointer;
  }

  .tpDialog_errorMinMax {
    position: absolute;
    color: var(--ErrColor_Darker);
    font-size: 0.6rem;
    margin-left: 5px;
  }