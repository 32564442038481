.warningColor {
  color: var(--WarnColor);
}

.errColor {
  color: var(--ErrColor);
}

.healthColor {
  color: var(--HealthColor);
}

.errBgc {
  background-color: var(--ErrColorBar);
}

.errBtnBgc {
  background-color: var(--ErrColor);
}

.healthBtnBgc {
  background-color: var(--HealthColor);
}
