.icon-button {
  font-size: x-large;
  padding-bottom: 3px;
}

.icon-button .user:hover {
  text-decoration: none;
  background-color: #dba100;
  border-radius: 20px;
  cursor: pointer;
}

.icon-button:hover ul {
  display: block;
  text-decoration: none;
}

.nav-item .user {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  font-size: x-large;
  text-align: center;
  height: 35px;
  width: 35px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #f59800;
}

/* .user .user-char {
  margin-bottom: -50px;
  background-color: thistle;
} */

.icon-button ul {
  position: absolute;
  background-color: #263238;
  border-radius: 10px;
  list-style: none;
  display: none;
  margin-top: 4px;
  margin-left: -120px;
  width: 180px;
}

.icon-button ul li {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
}

.profile-icon {
  margin-right: 5px;
  margin-bottom: 3px;
}

.dd-icon {
  color: #eceff1;
  font-size: large;
}

.dd-icon:hover {
  text-decoration: none;
  color: #f5f5f5;
}

.icon-button ul li:hover {
  text-decoration: none;
}

.logout {
  padding-top: 5px;
  padding-bottom: 5px;
}

.logout:hover {
  background-color: #37474f;
  border-radius: 10px;
  cursor: pointer;
}

.separator {
  border-top: 0.5px solid #78909c;
  margin-top: 5px;
  margin-bottom: 0;
}

.navbar {
  background-color: #263238;
}

.navbar-toggle {
  list-style: none;
  padding: 0px 0px 0px 23px;
  background-color: #f59800;
  width: 100%;
  height: 3.5rem;
  margin-bottom: 10px;
  /* border-bottom: solid;
  border-width: 1px;
  border-color: #00f5e6; */
}

/* expand button */
.menu-bars {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: large;
  background: none;
}

/* close button */
.menu-bars-x {
  font-size: xx-large;
}

.nav-menu {
  background-color: #263238;
  /* background-image: linear-gradient(170deg, rgb(0, 0, 0), rgb(40, 40, 40)); */
  height: 100%;
  width: 270px;
  position: fixed;
  z-index: 1032;
  top: 0;
  left: -100%;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; Add the ability to scroll */
  transition: 850ms;
  overflow: scroll;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav-menu.active::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
/* .cusSideBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.nav-text {
  padding: 8px 0px 8px 30px;
  list-style: none;
  height: 50px;
  font-size: large;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text:hover {
  text-decoration: none;
  color: #f5f5f5;
  background-color: #37474f;
}

.nav-text a:hover {
  text-decoration: none;
  color: #f5f5f5;
}

.nav-text a:active {
  background-color: #89878b;
}

.sub-nav-text {
  padding: 8px 0px 8px 50px;
  height: 50px;
}

.sub-nav-text:hover {
  background-color: #37474f;
}

.nav-menu-items {
  width: 100%;
}

span {
  margin-left: 16px;
}

.navbarlink:hover {
  margin-left: 5rem;
  text-decoration: none;
}

.sub-navbarlink:hover {
  text-decoration: none;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  /* background-image: linear-gradient(170deg, rgb(0, 0, 0), rgb(40, 40, 40)); */
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  display: none;
  z-index: 1031;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; Add the ability to scroll */
}

.overlay.active {
  display: block;
}

.nav-item-bdsel {
  max-width: 60%;
}

#bdsel {
  text-decoration: underline;
  color: #cfd8dc;
  font-size: large;
}

.bdsel-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#bdsel:hover {
  color: #90a4ae;
}

/* .nav-item-bdsel {
  color: #cfd8dc;
  font-size: large;
}

.nav-item-bdsel:hover {
  cursor: pointer;
  color: #90a4ae;
} */

.bdsel-icon {
  font-size: x-large;
  margin-right: 3px;
  /* padding-bottom: 2px; */
}

.bdsel-name {
  padding-bottom: -50px;
}
