.cfgCard {
  border-radius: 5px;
  /* background-color: var(--cardWhite); */
  margin: 10px;
  /* padding-left: 5px; */
  padding: 10px 5px 10px 10px;
}
.cfgCard_NoPadding {
  border-radius: 5px;
  /* background-color: var(--cardWhite); */
  margin: 10px;
  /* padding-left: 5px; */
  padding: 0px 5px;
}

.cfgCardColor {
  background-color: var(--cardWhite);
}

.cfgSubTitle {
  /* margin-left: 15px; */
  margin: 0px 15px -5px 15px;
  font-size: 1.1rem;
  padding-top: 5px;
}

.cardtitle{
  /* margin: 0px 10px 0px 15px; */
  margin: 0px -5px;
  font-size: 1.1rem;
  padding: 7px 5px 7px 10px;
  background-color: var(--cardTitleWhite);
  border-radius: 5px 5px 0px 0px;
}

.stayRightElem {
  width: 70px;
  /* background-color: rgb(38, 185, 70); */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.stayRightMultipleIcon {
  margin: 0px 10px 0px 5px;
  width: 30px;
  /* background-color: rgb(38, 185, 70); */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.cfgBdName {
  font-weight: 500;
  font-size: 1.15rem;
}

.cfgSmallDesc {
  font-size: 0.7rem;
  color: brown;
}

.cfgAccessLevelFrame {
  border-radius: 6px;
  background-color: var(--HealthColor);
  color: var(--whiteText);
  margin-right: 10px;
}
.cfgAccessLevelFrame:hover {
  cursor: pointer;
}

.cfgAccessLevelFrameSpacing{
  padding :1px 5px;
  margin: 5px 0px 0px 0px;
}

.cfgArrowStyle{
  /* background-color: rgb(203, 60, 60); */
  height: fit-content;
  margin: 5px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delTextDeco{
  color: var(--blueText);
}

.delLineDeco{
  color: var(--ErrColor_Darker);
}

.redBgc{
  background-color: rgb(227, 149, 149);
}

.cfgSensorUnderArea {
  padding-left: 20px;
  /* background-color: rgb(80, 28, 165); */
  max-width: 80%;
}

.cfgArea {
  padding-bottom: 10px;
}
.cfgAreaTitle {
  /* font-size: 1.1rem; */
  font-weight: 500;
  /* padding-bottom: 3px; */
  /* background-color: rgb(214, 43, 43); */
}

.cfgAreaWrapper {
  margin-bottom: 10px;
  /* background-color: rgb(10, 184, 77); */
}

.cfgEmailWidth {
  max-width: 80%;
}
.listPadding{
  padding: 5px 5px 5px 10px;
}

/* .cfgChooseBd:hover {
  cursor: pointer;
} */

.cfgOptionSel {
  background-color: var(--cardWhite);
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.cfgOptionSel:hover {
  cursor: pointer;
}

.cfgSearchBar {
  margin-left: 10px;
}

.cfgAddEmailButton:hover {
  cursor: pointer;
}

.cfgAddEmailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  /* background-color: rgb(67, 221, 114); */
}

.cfgAddEmailFrontEnd {
  /* background-color: rgb(13, 190, 137); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.cfgAddEmailContent {
  flex: 8;
}
.cfgMaxEmailMsg {
  flex: 8;
  color: var(--ErrColor);
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
}

.hoverPointer:hover {
  cursor: pointer;
}

.cfgEmailAddButtondeactive {
  color: var(--grey3);
}

.cfgEmailAddButtonActive {
  color: var(--HealthColor);
}

.cfgCheckContainer {
  position: relative;
}

.cfgCheckContent {
  position: absolute;
  top: -14px;
}

.cfgCheckDevContainer {
  margin: 4px 0px;
}

.cfgReactContentTitle_L {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 10px;
}
