.sumCardContainer {
  width: 100%;
  /* background-color: rgb(55, 139, 218); */
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-evenly;
}

.sumCard {
  width: 30%;
  /* background-color: rgb(88, 11, 11); */
}
