.headerContainer {
  margin: 20px 10px 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: rgb(64, 211, 101); */
  height: 40px;
}

.singleSensorHeaderContainer {
  display: flex;
  align-items: center;
}

.searchBarContainer {
  height: 40px;
  width: 70vw;
  display: flex;
  flex: content;
  align-items: center;
  border: 1px solid var(--grey4);
  border-radius: 6px;
}

.searchBarContainer_new {
  height: 40px;
  width: 80%;
  display: flex;
  flex: content;
  align-items: center;
  border: 1px solid var(--grey4);
  border-radius: 6px;
}

.searchBarContainer_non {
  height: 40px;
  /* width: 90%; */
  display: flex;
  flex: content;
  align-items: center;
  border: 1px solid var(--grey4);
  border-radius: 6px;
}

.searchBarContainer_90P {
  height: 40px;
  width: 90%;
  display: flex;
  flex: content;
  align-items: center;
  border: 1px solid var(--grey4);
  border-radius: 6px;
}
.searchBarContainer_80P {
  height: 40px;
  width: 80%;
  display: flex;
  flex: content;
  align-items: center;
  border: 1px solid var(--grey4);
  border-radius: 6px;
}
.stickSearchBar {
  background-color: var(--bgc);
  margin: 10px 20px 0px;
}

.withBgc {
  background-color: var(--bgc);
  /* background-color: red; */
}

.searchInput {
  background-color: var(--bgc);
  margin-left: 10px;
  width: 100%;
  /* flex: content; */
  /* flex-wrap: wrap; */
  outline: none;
  border: none;
}

.searchInput:focus {
  outline: none;
  /* border: 1px solid black; */
  /* background-color: rgb(214, 28, 28); */
}

.searchButton {
  /* background-color: rgb(240, 50, 50); */
  /* width: 40px; */
  display: flex;
  padding-right: 15px;
  align-items: center;
  /* border: 1px solid black; */
}

.menuContainer {
  /* width: 40px; */
  padding: 0px 10px;
  /* align-self: flex-end; */
  margin-left: auto;
  /* background-color: rgb(201, 46, 46); */
  /* float: right; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerIcon {
  width: 30px;
  /* width: 5vw; */
  height: 30px;
  border-radius: 20%;
  font-size: 1.2rem;
  /* margin-left: 3px; */
  /* margin-left: auto; */
  /* background-color: rgb(201, 46, 46); */
  /* float: right; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerIconLeft{
  width: 30px;
  /* width: 5vw; */
  height: 30px;
  border-radius: 20%;
  font-size: 1.2rem;
  /* margin-right: 3px; */
  margin-left: -10px;
  /* background-color: rgb(201, 46, 46); */
  /* float: right; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerCompareIcon {
  background-color: rgb(235, 177, 130);
}

.singleSensorBackContainer {
  /* padding: 0px 10px; */
  /* align-self: flex-end; */
  /* margin-right: auto; */
}

.backContainer {
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  background-color: var(--headerColor);
}

.backIconContainer {
  display: flex;
  /* align-items: left; */
  padding: 10px 10px 10px 0px;
  /* background-color: rgb(238, 38, 38); */
}

.backIconContainer:hover {
  cursor: pointer;
}

.backIcon {
  /* color: aqua; */
  /* background-color: rgb(182, 40, 40); */
}

.backText {
  padding-left: 15px;
}
